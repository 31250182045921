import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom"; 
import classes from './NewOrderLoadingEmployee.module.css'; 

const LoadingPage = () => {
  const navigate = useNavigate();


  return (
    <div className={classes.loadingContainer}>
      <div className={classes.loadingSpinner}></div>
      <p>Παρακαλώ περιμένετε...</p>
    </div>
  );
};

export default LoadingPage;
