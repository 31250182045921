import React,{useState,useEffect, Fragment} from 'react';
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";
import {useCookies} from 'react-cookie';
import Modal from '../../UI/Modal';
import Input from '../../UI/Input';
import Button from '../../UI/Button';
import OrderContext from '../../../store/order-context'
import classes from './Views.module.css';
import axios from 'axios';

const XaliView = (props) =>{
  //states
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const [isLoaded, setIsLoaded] = useState(false);
  const [productInfo, setProductInfo] = useState(null);
  const [productOptions, setProductOptions] = useState(null);

  useEffect(() => {
    setIsLoaded(true);
}, []);

  useEffect(() => {
    console.log('proped id is :' +props.id);
    const config = {
      headers: { Authorization: "Bearer 5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp"}
    };
    axios.post('https://api.orders.e-interior.gr/api/product/'+props.id,{
      key: cookies.Token},
      config
    )
    .then(function (response) {
      console.log(response.data);
      setProductInfo(response.data)
      setProductOptions(JSON.parse(response.data.product_options))
    })
    .catch(function (error) {if (error.response.data.message==="Unauthenticated."){
        removeCookies('Name', { path: '/' });
        removeCookies('Token', { path: '/' });
        navigate("/user-login");
      }
      console.log(error);
    });
  },[isLoaded])

  return(
    <Modal className={classes.modal} onClose={props.onClose}>
      {productInfo && 
      <Fragment>
      <h2 className={classes.title}>Παραγγελία {productInfo.product_type}</h2>
      <p>Είδος: {productInfo.product_type}</p>
      <p>Εταιρεία: {productInfo.manufacturer}</p>
      <p>Κωδικός: {productInfo.code}</p>

      </Fragment>
      }
      {productOptions &&
      <Fragment>
        <p>Ποσότητα: {productOptions.quantity}</p>
        {(productOptions.dimensions!=="0 X 0"&&productOptions.dimensions!=="")&&<p>Διαστάσεις: {productOptions.dimensions}</p>}
        {(productOptions.dimensionsOne!=="0 X 0"&&productOptions.dimensionsOne!=="")&&<p>Κομμάτι 1 Διαστάσεις: {productOptions.dimensionsOne}</p>}
        {(productOptions.dimensionsTwo!=="0 X 0"&&productOptions.dimensionsTwo!=="")&&<p>Κομμάτι 2 Διαστάσεις: {productOptions.dimensionsTwo}</p>}
        {(productOptions.dimensionsThree!=="0 X 0"&&productOptions.dimensionsThree!=="")&&<p>Κομμάτι 3 Διαστάσεις: {productOptions.dimensionsThree}</p>}
        {productOptions.squarePrice&&<p>Τιμή μέτρου: {productOptions.squarePrice}€</p>}
        {!productInfo.product_type.includes("χαλί")&&
          <Fragment>
          <p>Τοποθέτηση: {productOptions.installation==="nai"?"Ναι":"Όχι"}</p>
          {productOptions.installation==="nai"&&<p>Κόστος τοποθέτησης: {productOptions.installPrice}€</p>}
          </Fragment>
        }
        <p>Τιμή: {productOptions.totalPrice}€</p>
        {productOptions.freeText&&<p>Σημειώσεις: {productOptions.freeText}</p>}
        <p>Πελάτης: {props.client_name}</p>
        <button className={classes.close} onClick={props.onClose}>Πίσω</button>
      </Fragment>
      }
    </Modal>
  )
}

export default XaliView;
