import React,{useState,useContext,useEffect,Fragment} from 'react';
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";
import {useCookies} from 'react-cookie';
import Input from '../../UI/Input';
import Button from '../../UI/Button';
import OrderContext from '../../../store/order-context'
import classes from './XaliMoketaPlastiko.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { Typeahead } from 'react-bootstrap-typeahead'; 

const XaliMoketaPlastiko = (props) =>{


  //states
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const [companies, setCompanies]=useState('');
  const [companySelection, setCompanySelection] = useState([]);
  const [companyDropdown, setCompanyDropdown] = useState('');
  const [sku, setSku]=useState('');
  const [quantity, setQuantity]=useState('1');
  const [typeSelection,setTypeSelection]=useState('0');
  //etoimo xali
  const [dimensions,setDimensions]=useState('');
  const [dimensionsOne, setDimensionsOne]=useState('');
  const [dimensionsTwo,setDimensionsTwo]=useState('');
  const [dimensionsThree,setDimensionsThree]=useState('');
  const [preCarpetPrice, setPreCarpetPrice]=useState('');
  const [selectedPrice, setSelectedPrice]=useState('');
  const [freeText, setFreeText]=useState('');

  //moketa
  const [squarePrice, setSquarePrice]=useState('');
  const [width,setWidth]=useState('');
  const [length,setLength]=useState('');
  const [widthOne,setWidthOne]=useState('');
  const [lengthOne,setLengthOne]=useState('');
  const [widthTwo,setWidthTwo]=useState('');
  const [lengthTwo,setLengthTwo]=useState('');
  const [widthThree,setWidthThree]=useState('');
  const [lengthThree,setLengthThree]=useState('');
  const [customCarpetPrice, setCustomCarpetPrice]=useState('');
  const [bedroomPrice, setBedroomPrice]= useState('');
  const [installCarpetSelection, setInstallCarpetSelection]=useState('oxi');
  const [manualInstallPrice, setManualInstallPrice] = useState('');
  const [installPrice, setInstallPrice]=useState('');
  const [errorMessage, setErrorMessage] =useState("");

  const [isLoaded, setIsLoaded] = useState(false);

  const [discount, setDiscount] = useState(false);
  const [discountPrice, setDiscountPrice] = useState('');
  const [totalPreCarpetPrice, setTotalPreCarpetPrice]=useState('');
  const [totalPriceDiscount, setTotalPriceDiscount]=useState('');
  const [options,setOptions]=useState({id:'',label:''});

  const [buttonDisabled, setButtonDisabled] = useState(false);
  
  useEffect(() => {
    setIsLoaded(true);
}, []);
  useEffect(() => {
    const config = {
      headers: { 'Authorization': 'Bearer '+'5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp'}
    };
    axios.post('https://api.orders.e-interior.gr/api/general/init',{
      key: '5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp'},
      config
    )
    .then(function (response) {
      setCompanies(response.data[0].companies);

    })
    .catch(function (error) {
      console.log(error);
    });

  },[isLoaded])

  //change handlers
  const discountChangeHandler = (event) =>{
    if (discount){
      setDiscount(false);
    }else{
      setDiscount(true);
    }
  }
  const discountPriceChangeHandler = (event) =>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setDiscountPrice(value);
    }
    
  }

  const companySelectionHandler = (event) =>{
    setCompanySelection(event.target.value);
  }
  const skuChangeHandler = (event)=>{
    setSku(event.target.value);
  }
  const quantityChangeHandler = (event)=>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    value = value.trim();
    if (value === '') {
      setQuantity(''); // Set quantity as an empty string if input is empty
    } else {
      value = parseInt(value);
      if (isNaN(value)) {
        setQuantity(''); // Set quantity as an empty string if value is NaN
      } else {
        setQuantity(value);
      }
    }
  }
  const selectTypeHandler = (event) =>{
    setTypeSelection(event.target.value);
    setSelectedPrice('');
    setPreCarpetPrice('');
    setCustomCarpetPrice('');
    setBedroomPrice('');
    setWidth('');
    setLength('');
    setWidthOne('');
    setLengthOne('');
    setWidthTwo('');
    setLengthTwo('');
    setWidthThree('');
    setLengthThree('');
    setDimensions('');
    setDimensionsOne('');
    setDimensionsTwo('');
    setDimensionsThree('');
  }
  const dimensionsChangeHandler = (event) =>{
    setDimensions(event.target.value);
  }
  const preCarpetPriceChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setSelectedPrice(value);
      setPreCarpetPrice(value);
    }
  }
  const freeTextChangeHandler = (event) =>{
    setFreeText(event.target.value);
  }
  const squarePriceChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setSquarePrice(value);
    }
  }
  const widthChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setWidth(value);
    }
  }
  const lengthChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setLength(value);
    }
  }
  const widthOneChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setWidthOne(value);
    }
  }
  const lengthOneChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setLengthOne(value);
    }
  }
  const widthTwoChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setWidthTwo(value);
    }
  }
  const lengthTwoChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setLengthTwo(value);
    }
  }
  const widthThreeChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setWidthThree(value);
    }
  }
  const lengthThreeChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setLengthThree(value);
    }
  }
  const installCarpetSelectionHandler = (event) =>{
    setInstallCarpetSelection(event.target.value);
  }
  const installPriceChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setInstallPrice(value);
      setManualInstallPrice(value);
    }
  }

  useEffect(()=>{
    if (typeSelection!=="preCarpet"&&typeSelection!=="setKrevatokamaras"){
      setSelectedPrice(Math.round(Number(width)*Number(length)*Number(squarePrice)*100)/100);
      setCustomCarpetPrice(Math.round(Number(width)*Number(length)*Number(squarePrice)*quantity*100)/100);
    }
  },[width,length,squarePrice,quantity,typeSelection]) 

  useEffect(()=>{
    if (typeSelection==="setKrevatokamaras"){
      let pieceOne = Number(widthOne)*Number(lengthOne)
      let pieceTwo = Number(widthTwo)*Number(lengthTwo)
      let pieceThree = Number(widthThree)*Number(lengthThree)
      setSelectedPrice(Math.round((pieceOne+pieceTwo+pieceThree)*Number(squarePrice)*100)/100);
      setBedroomPrice(Math.round((pieceOne+pieceTwo+pieceThree)*Number(squarePrice)*quantity*100)/100);
    }
  },[widthOne,lengthOne,widthTwo,lengthTwo,widthThree,lengthThree,squarePrice,quantity,typeSelection]) 


  useEffect(()=>{
    setDimensions(Math.round(width*100)+' X '+Math.round(length*100));
  },[width,length])

  useEffect(()=>{
    setDimensionsOne(Math.round(widthOne*100)+' X '+Math.round(lengthOne*100));
  },[widthOne, lengthOne])

  useEffect(()=>{
    setDimensionsTwo(Math.round(widthTwo*100)+' X '+Math.round(lengthTwo*100));
  },[widthTwo,lengthTwo])

  useEffect(()=>{
    setDimensionsThree(Math.round(widthThree*100)+' X '+Math.round(lengthThree*100));
  },[widthThree,lengthThree])

  const handleSaveClick = () =>{
  if (sku!='0'&&typeSelection!='0' && !buttonDisabled){
    setButtonDisabled(true)
    const config = {
      headers: { 'Authorization': 'Bearer '+'5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp'}
    };
    let totalPrice = '0';
    if (typeSelection== "preCarpet"){
      totalPrice = totalPreCarpetPrice;
    }else if (typeSelection =="customCarpet"||typeSelection=="plastikoDapedo"||typeSelection=="gazon"){
      totalPrice = customCarpetPrice;
    }else if (typeSelection == "setKrevatokamaras"){
      totalPrice = bedroomPrice;
    }
    const productOptions = JSON.stringify({
        quantity: quantity,
        dimensions: dimensions,
        dimensionsOne: dimensionsOne,
        dimensionsTwo: dimensionsTwo,
        dimensionsThree: dimensionsThree,
        freeText: freeText,
        squarePrice: squarePrice,
        width: width,
        length: length,
        installation: installCarpetSelection,
        installPrice: installCarpetSelection==='nai'?installPrice:'',
        discount: discount,
        discountPrice: discount ? discountPrice:'',
        totalPriceDiscount: discount ? totalPriceDiscount:'',
        totalPrice: totalPrice
      })
    let typeString ='';
    switch(typeSelection){
    case 'preCarpet':
      typeString="Έτοιμο χαλί"
      break;
    case 'customCarpet':
      typeString="Μοκέτα"
      break;
    case 'gazon':
      typeString="Γκαζόν"
      break;
    case 'plastikoDapedo':
      typeString="Πλαστικό Δάπεδο"
      break;
    case 'setKrevatokamaras':
      typeString="Σετ Κρεβατοκάμαρας"
      break;
    }
    axios.post('https://api.orders.e-interior.gr/api/product/create',{
      key: '5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp',
      order_id: props.OrderId?props.OrderId:cookies.OrderId,
      product_type: typeString,
      manufacturer: companySelection[0] ? companySelection[0].label : 0,
      code: sku,
      product_options: productOptions,
      status: "submitted",
      ordered_at: null
      },
      config
    )
    .then(function (response) {
      setButtonDisabled(false)
      console.log(response.data);
      props.onSave({data:response.data})

    })
    .catch(function (error) {
      setButtonDisabled(false)
      console.log(error);
    });
  }else{
    setErrorMessage('Λείπουν υποχρεωτικά πεδία');
  }
  }
useEffect(()=>{
      if (companies){
        setOptions(companies.map((key) => ({
                id: key,
                label: `${key}`,
              })))}
    }, [companies])

useEffect (()=>{
  if (typeSelection === 'preCarpet'){
      setTotalPreCarpetPrice(preCarpetPrice*quantity);
    }
  },[preCarpetPrice,quantity])

useEffect(()=>{
      setTotalPriceDiscount(Number(discountPrice)*Number(quantity));
    },[discountPrice,quantity])

    useEffect (()=>{
      if (manualInstallPrice>0){
        setInstallPrice(manualInstallPrice*quantity);
      }
    },[quantity])

return(
<div className={classes.newProduct}>
  <h2>Παραγγελία Χαλιού</h2>
    {errorMessage}
    <div className={classes.textarea}>
    <label htmlFor="sku">Κωδικός/Περιγραφή: </label>
    <textarea id="sku" rows="2" value={sku} onChange={skuChangeHandler}></textarea>
    </div>
    <div className={classes.searchBlock}>
        <span>
          <label htmlFor="type">Εταιρεία: </label>
            {options.length>0 ? 
            <Typeahead
                className={classes.typeaheadSelect}
                id="basic-typeahead-single"
                labelKey="label"
                onChange={setCompanySelection}
                options={options}
                placeholder=""
                defaultSelected={companySelection}
                selected={companySelection}
            />:
            <FontAwesomeIcon className={classes.loadingIcon} icon={faCircleNotch} spin />
          }
        </span>
    </div>
    
    <div className={classes.searchBlock}>
      <span>
        <label htmlFor="type">Τύπος: </label>
        <select id="type" name="type" onChange={selectTypeHandler} value={typeSelection}>
          <option value="0"> </option>
          <option value="preCarpet">Έτοιμο Χαλί</option>
          <option value="customCarpet">Μοκέτα</option>
          <option value="gazon">Γκαζόν</option>
          <option value="plastikoDapedo">Πλαστικό Δάπεδο</option>
          <option value="setKrevatokamaras">Σετ Κρεβατοκάμαρας</option>
        </select>
      </span>
    </div>
    {typeSelection === "preCarpet"
    &&
    <Fragment>
    <Input
    onChange={dimensionsChangeHandler}
    label="Διάσταση (cm):"
    vert="true"
    input={{
      id: 1,
      type: 'text',
      value:dimensions
    }} />
    <br/>
    <Input onChange={preCarpetPriceChangeHandler}
    label="Τιμή:"
    vert="true"
    isPrice="true"
    input={{
      id: 1,
      type: 'text',
      value: preCarpetPrice
    }} />
    {quantity>1&&<p>Σύνολο: {totalPreCarpetPrice}€</p>}
    {preCarpetPrice>0 && <div>
      <Button className={classes.discountBtn} onClick={discountChangeHandler}>Έκπτωση;</Button>
      {!discount && <p></p>}
      {discount && 
      <Fragment>
      <Input
      onChange={discountPriceChangeHandler}
      label="Τιμή με έκπτωση:"
      isPrice="true"
      vert="true"
      input={{
        id: 1,
        type: 'text',
        value: discountPrice
      }} />
      <p>Σύνολο με έκπτωση: {totalPriceDiscount}€</p>
      </Fragment>}
      </div>}
    <div className={classes.textarea}>
    <label htmlFor="sku">Σημειώσεις: </label>
    <textarea id="sku" rows="2" value={freeText} onChange={freeTextChangeHandler}></textarea>
    </div>
    </Fragment>
  }
  {(typeSelection === "customCarpet" || typeSelection==="plastikoDapedo" || typeSelection==="gazon")
  &&
  <Fragment>
  <Input
  onChange={squarePriceChangeHandler}
  label="Τιμή Τετραγωνικού:"
  vert="true"
  isPrice="true"
  input={{
    id: 1,
    type: 'text',
    value: squarePrice
  }} />
  <br/>
  <Input onChange={widthChangeHandler}
  label="Πλάτος (m):"
  vert="true"
  input={{
    id: 1,
    type: 'text',
    value: width
  }} />
  <br/>
  <Input onChange={lengthChangeHandler}
  label="Μήκος (m):"
  vert="true"
  input={{
    id: 1,
    type: 'text',
    value: length
  }} />
  <br/>
  <p className={classes.hint}>{(width.length>0 && length.length>0) && dimensions}</p>
  <div className={classes.calculation}>
  {(width.length>0 && length.length>0 && squarePrice.length>0)&& <p className={classes.priceFixed}>{"Τιμή: "+customCarpetPrice+'€'}</p>}
  <p className={classes.hint}>{(width.length>0 && length.length>0 && squarePrice.length>0)&&"(Πλάτος(m) Χ Μήκος(m)) Χ Τιμή Τετραγωνικού"}</p>
  </div>
  <div className={classes.textarea}>
    <label htmlFor="sku">Σημειώσεις: </label>
    <textarea id="sku" rows="2" value={freeText} onChange={freeTextChangeHandler}></textarea>
    </div>
    {customCarpetPrice>0 && <div>
    <Button className={classes.discountBtn} onClick={discountChangeHandler}>Έκπτωση;</Button>
    {!discount && <p></p>}
    {discount && 
      <Fragment>
      <Input
      onChange={discountPriceChangeHandler}
      label="Τιμή με έκπτωση:"
      vert="true"
      isPrice="true"
      input={{
        id: 1,
        type: 'text',
        value: discountPrice
      }} />
      <p>Σύνολο με έκπτωση: {totalPriceDiscount}€</p>
      </Fragment>}
    </div>}
  <div className={classes.searchBlock}>
    <span>
      <label htmlFor="install">Τοποθέτηση: </label>
      <select id="install" name="install" onChange={installCarpetSelectionHandler} value={installCarpetSelection}>
        <option value="oxi">Όχι</option>
        <option value="nai">Ναι</option>
      </select>
    </span>
  </div>

  </Fragment>
}
{/*Set krevatokamaras*/}
{typeSelection === "setKrevatokamaras"
  &&
  <Fragment>
  <Input
  onChange={squarePriceChangeHandler}
  label="Τιμή Τετραγωνικού:"
  vert="true"
  isPrice="true"
  input={{
    id: 1,
    type: 'text',
    value: squarePrice
  }} />
  <br/>
  <div className={classes.setDimensions}>
    <p>Κομμάτι 1:</p>
    <Input onChange={widthOneChangeHandler}
    label="Πλάτος (m):"
    vert="true"
    input={{
      id: 1,
      type: 'text',
      value: widthOne
    }} />
    <br/>
    <Input onChange={lengthOneChangeHandler}
    label="Μήκος (m):"
    vert="true"
    input={{
      id: 1,
      type: 'text',
      value: lengthOne
    }} />
    <br/>
    <p className={classes.hint}>{(widthOne.length>0 && lengthOne.length>0) && dimensionsOne}</p>
  </div>
  <div className={classes.setDimensions}>
  <p>Κομμάτι 2:</p>
    <Input onChange={widthTwoChangeHandler}
    label="Πλάτος (m):"
    vert="true"
    input={{
      id: 1,
      type: 'text',
      value: widthTwo
    }} />
    <br/>
    <Input onChange={lengthTwoChangeHandler}
    label="Μήκος (m):"
    vert="true"
    input={{
      id: 1,
      type: 'text',
      value: lengthTwo
    }} />
    <p className={classes.hint}>{(widthTwo.length>0 && lengthTwo.length>0) && dimensionsTwo}</p>
  </div>
  <div className={classes.setDimensions}>
  <p>Κομμάτι 3:</p>
    <Input onChange={widthThreeChangeHandler}
    label="Πλάτος (m):"
    vert="true"
    input={{
      id: 1,
      type: 'text',
      value: widthThree
    }} />
    <br/>
    <Input onChange={lengthThreeChangeHandler}
    label="Μήκος (m):"
    vert="true"
    input={{
      id: 1,
      type: 'text',
      value: lengthThree
    }} />
    <p className={classes.hint}>{(widthThree.length>0 && lengthThree.length>0) && dimensionsThree}</p>
  </div>
  
  <div className={classes.calculation}>
  {(bedroomPrice>0 && squarePrice.length>0)&& <p className={classes.priceFixed}>{"Τιμή: "+bedroomPrice+'€'}</p>}
  <p className={classes.hint}>{(bedroomPrice.length>0 && squarePrice.length>0)&&"((Πλάτος1(m) Χ Μήκος1(m)+(Πλάτος2(m) Χ Μήκος2(m)+(Πλάτος3(m) Χ Μήκος3(m)) Χ Τιμή Τετραγωνικού"}</p>
  </div>
  <div className={classes.textarea}>
    <label htmlFor="sku">Σημειώσεις: </label>
    <textarea id="sku" rows="2" value={freeText} onChange={freeTextChangeHandler}></textarea>
    </div>
    {bedroomPrice>0 && <div>
    <Button className={classes.discountBtn} onClick={discountChangeHandler}>Έκπτωση;</Button>
    {!discount && <p></p>}
    {discount && 
      <Fragment>
      <Input
      onChange={discountPriceChangeHandler}
      label="Τιμή με έκπτωση:"
      isPrice="true"
      input={{
        id: 1,
        type: 'text',
        value: discountPrice
      }} />
      <p>Σύνολο με έκπτωση: {totalPriceDiscount}€</p>
      </Fragment>}
    </div>}
  <div className={classes.searchBlock}>
    <span>
      <label htmlFor="install">Τοποθέτηση: </label>
      <select id="install" name="install" onChange={installCarpetSelectionHandler} value={installCarpetSelection}>
        <option value="oxi">Όχι</option>
        <option value="nai">Ναι</option>
      </select>
    </span>
  </div>

  </Fragment>
}
<Input
      onChange={quantityChangeHandler}
      label="Ποσότητα:"
      vert="true"
      input={{
        id: 1,
        type: 'text',
        value: quantity
      }} />
  <div className={classes.actions}>
    <Button className={classes[`button--alt`]} onClick={props.onClose}>Πίσω</Button>
        <Button className={`${classes[`button--alt`]} ${buttonDisabled? classes.buttonLoad:''}`} disabled={buttonDisabled} onClick={handleSaveClick} >{buttonDisabled?<FontAwesomeIcon icon={faCircleNotch} spin />:'Αποθήκευση'}</Button>
  </div>
</div>
)}

export default XaliMoketaPlastiko;
