import React,{useState,useEffect, Fragment} from 'react';
import Card from '../UI/Card';
import { Navigate, Route, Routes, useNavigate,Outlet, Link, useLocation  } from "react-router-dom";
import {useCookies} from 'react-cookie';
import SingleOrderNotSubmitted from './SingleOrderNotSubmitted'
import DeletePermanently from './DeletePermanently';
import EditNotSubmitted from './EditNotSubmitted';
import ViewOrderNotSubmitted from './ViewOrderNotSubmitted';
import classes from './OrdersNotSubmitted.module.css';
import axios from 'axios';
import Input from '../UI/Input';
import Button from '../UI/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPencilAlt, faSave } from '@fortawesome/free-solid-svg-icons';
import { Typeahead } from 'react-bootstrap-typeahead'; 


const pencil = <FontAwesomeIcon icon={faPencilAlt} />
const save = <FontAwesomeIcon icon={faSave} />

const OrdersNotSubmitted = () =>{
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const [scrollPos, setScrollPos] = useState(0);
  const [showNotSubmitted, setShowNotSubmitted] = useState(false);
  const [orders,setOrders] = useState([]);
  const [clients,setClients] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [editClick,setEditClick] = useState(true);
  const [modalShow,setModalShow] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [returnModal, setReturnModal] = useState(false);
  const [currentId,setCurrentId] = useState('');
  const [currentFullName,setCurrentFullName] = useState('');
  const [currentStatus, setCurrentStatus] = useState('');
  const [saved, setSaved] = useState('');
  const [statusChange, setStatusChange] = useState(false);
  const [balance, setBalance] = useState(0);
  const [orderRender, setOrderRender] = useState('')
  const [currentClients, setCurrentClients] = useState('');
  const [nameSelection, setNameSelection] = useState('0')
  const location = useLocation();

  const [singleSelections, setSingleSelections] = useState([]);
  const [options,setOptions]=useState([{id:'',label:''}])



    useEffect(() => {
      setIsLoaded(true);
      if(location.state){
      setCurrentId(location.state.currentId);
      setCurrentFullName(location.state.currentFullName);
      setModalShow(true);
    }

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://api.orders.e-interior.gr/api/verify',
    headers: { 
      'Accept': 'application/json', 
      'Authorization': 'Bearer '+cookies.Token, 
    },
  };

  axios.request(config)
  .then((response) => {
  
    if (response.data.message!=="Success"){
      removeCookies('Name', { path: '/' });
      removeCookies('Token', { path: '/' });
      navigate("/user-login");
    };
  })
  .catch((error) => {
    removeCookies('Name', { path: '/' });
    removeCookies('Token', { path: '/' });
    navigate("/user-login");
  });

  }, []);

    useEffect(()=>{
      document.querySelector("html").scrollTo(0, scrollPos);
    }, [scrollPos,orderRender])

    useEffect(() => {
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
    };
    //get all orders 
    axios.post('https://api.orders.e-interior.gr/api/orders',{
      key: cookies.Token},
      config
    )
    .then(function (response) {

      setOrders(response.data.sort((a, b) => b.id - a.id));
    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
            removeCookies('Name', { path: '/' });
            removeCookies('Token', { path: '/' });
            navigate("/user-login");
          }
      console.log(error);
    });

    //get all clients
    axios.post('https://api.orders.e-interior.gr/api/clients',{
      key: cookies.Token},
      config
    )
    .then(function (response) {
      setClients(response.data);

    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
            removeCookies('Name', { path: '/' });
            removeCookies('Token', { path: '/' });
            navigate("/user-login");
          }
      console.log(error);
    });

  },[isLoaded, modalShow, statusChange])

  function handleClearClick(){
    setSingleSelections([]);
  }

  useEffect(()=>{
    setCurrentClients([...new Set(orders.filter(order =>
      order.status !== "not_submitted_yet" && 
      order.status !== "completed" && 
      order.status !== "canceled"
      ).map(item => item.fullName).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())))]);
  },[orders])

  useEffect(()=>{

    let code = ''
    let notes = '';
    let labelStr = '';
    let opt = [];
    let filteredOrders = orders.filter(order => 
      order.status === "not_submitted_yet")
    for (let order of filteredOrders){
      for (let product of order.products){
        if (product.status!=='deleted'){
        code = product.code
        notes = JSON.parse(product.product_options).notes ? JSON.parse(product.product_options).notes:JSON.parse(product.product_options).freeText?JSON.parse(product.product_options).freeText:'';
        labelStr =code+(notes?' '+notes:'');
        opt.push({
          id: Math.floor(Math.random() * 1000000),
          label: labelStr,
          })
      }
      }
    }
    for (let client of currentClients){
      opt.push({
          id: Math.floor(Math.random() * 1000000),
          label: client,
          })
    }
    opt = opt.reduce((acc, cur) => {
      if (!acc.includes(cur.label)) {
        acc.push(cur.label);
      }
      return acc;
    }, [])
    setOptions(opt)
}, [orders])

  const handleModalShow = (e) =>{
    setModalShow(e.modal);
    setCurrentId(e.id);
    setCurrentStatus(e.status);
    setScrollPos(e.scrollPos);
  }

  const onCloseHandler = () =>{
    setModalShow(false);
    setCurrentId('');
    setCurrentStatus('');
    setStatusChange(!statusChange);
  }


  const nameSelectionHandler = (e) =>{
    setNameSelection(e.target.value);
  } 

  
  
  const handleDeleteClick = (e) =>{
    setDeleteModal(true);
    setCurrentId(e.id);
  }
  const handleDeleteYes = (e) =>{
    setDeleteModal(false);
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
    axios.post('https://api.orders.e-interior.gr/api/order/update',{
      key: cookies.Token,
      order_id: e.id,
      status: "deleted"
      },
      config)
    .then(function (response) {
      setStatusChange(response.data)
    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
            removeCookies('Name', { path: '/' });
            removeCookies('Token', { path: '/' });
            navigate("/user-login");
          }
      console.log(error);
    });
  }
  const handleDeleteNo = (e) =>{
    setDeleteModal(false);
  }

  const handleReturnClick = (e) =>{
    setReturnModal(true);
    setCurrentId(e.id);
  }

  const handleReturnYes = (e) =>{
    setReturnModal(false);
    setCookies('OrderId',e.id,{path:'/'});
    navigate('../new-order/1');
  }
  const handleReturnNo = (e) =>{
    setReturnModal(false);
  }

  const handleViewReturn = (e) =>{
    setReturnModal(false);
    setCookies('OrderId',e.id,{path:'/'});
    navigate('../new-order/1');
  }
  const handleViewDelete = (e) =>{
    setDeleteModal(true);
    console.log(e.id);
    setCurrentId(e.id);
    setModalShow(false);
  }

  useEffect(()=>{
    if (showNotSubmitted) {
      const orderComponents = orders.filter(order =>
        order.status === "not_submitted_yet" 
        ).map(order => {
            if (singleSelections.length===0){
            return(
              <SingleOrderNotSubmitted
                modalShow={handleModalShow}
                deleteClick={handleDeleteClick}
                returnClick={handleReturnClick}
                statusChange={statusChange}
                id={order.id}
                key={order.id}
                fullName={order.fullName}
                vip={order.vip}
                phone={order.client!==null?order.client.phone:''}
                notes={JSON.parse(order.extras).notes}
                totalProducts={order.totalProducts}
                totalPrice={order.totalPrice}
                payment={JSON.parse(order.payment)}
                status={order.status}
                created_at={order.created_at}
                hasInstallation={order.hasInstallation}
                status_updated_at={order.status_updated_at}
                products={order.products}
              />
            )}else{
              let codeList = [];
              let noteList = []
              for (let product of order.products){
                codeList.push(product.code);
                if (JSON.parse(product.product_options).notes){
                  noteList.push(JSON.parse(product.product_options).notes);
                }else if(JSON.parse(product.product_options).freeText){
                  noteList.push(JSON.parse(product.product_options).freeText)
                }else{
                  noteList.push('');
                }
              }
              const combinedList = codeList.map((element, index) => element + ' '+noteList[index]);
              
              if (singleSelections){
                if((combinedList.map(item => item.trim()).includes(singleSelections[0].trim())) ||
                  singleSelections[0] === order.fullName){

                  return(
                    <SingleOrderNotSubmitted
                      modalShow={handleModalShow}
                      deleteClick={handleDeleteClick}
                      returnClick={handleReturnClick}
                      statusChange={statusChange}
                      id={order.id}
                      key={order.id}
                      fullName={order.fullName}
                      vip={order.vip}
                      phone={order.client!==null?order.client.phone:''}
                      notes={JSON.parse(order.extras).notes}
                      totalProducts={order.totalProducts}
                      totalPrice={order.totalPrice}
                      payment={JSON.parse(order.payment)}
                      status={order.status}
                      created_at={order.created_at}
                      hasInstallation={order.hasInstallation}
                      status_updated_at={order.status_updated_at}
                      products={order.products}
                    />
                  )
                }
              }
            }
        });
        setOrderRender(orderComponents);
      }else{
        const orderComponents = orders.filter(order =>
        order.status === "employee_submitted" 
        ).map(order => {
            if (singleSelections.length===0){
            return(
              <SingleOrderNotSubmitted
                modalShow={handleModalShow}
                deleteClick={handleDeleteClick}
                returnClick={handleReturnClick}
                statusChange={statusChange}
                id={order.id}
                key={order.id}
                fullName={order.fullName}
                vip={order.vip}
                phone={order.client!==null?order.client.phone:''}
                notes={JSON.parse(order.extras).notes}
                totalProducts={order.totalProducts}
                totalPrice={order.totalPrice}
                payment={JSON.parse(order.payment)}
                status={order.status}
                created_at={order.created_at}
                hasInstallation={order.hasInstallation}
                status_updated_at={order.status_updated_at}
                products={order.products}
              />
            )}else{
              let codeList = [];
              let noteList = []
              for (let product of order.products){
                codeList.push(product.code);
                if (JSON.parse(product.product_options).notes){
                  noteList.push(JSON.parse(product.product_options).notes);
                }else if(JSON.parse(product.product_options).freeText){
                  noteList.push(JSON.parse(product.product_options).freeText)
                }else{
                  noteList.push('');
                }
              }
              const combinedList = codeList.map((element, index) => element + ' '+noteList[index]);
              
              if (singleSelections){
                if((combinedList.map(item => item.trim()).includes(singleSelections[0].trim())) ||
                  singleSelections[0] === order.fullName){

                  return(
                    <SingleOrderNotSubmitted
                      modalShow={handleModalShow}
                      deleteClick={handleDeleteClick}
                      returnClick={handleReturnClick}
                      statusChange={statusChange}
                      id={order.id}
                      key={order.id}
                      fullName={order.fullName}
                      vip={order.vip}
                      phone={order.client!==null?order.client.phone:''}
                      notes={JSON.parse(order.extras).notes}
                      totalProducts={order.totalProducts}
                      totalPrice={order.totalPrice}
                      payment={JSON.parse(order.payment)}
                      status={order.status}
                      created_at={order.created_at}
                      hasInstallation={order.hasInstallation}
                      status_updated_at={order.status_updated_at}
                      products={order.products}
                    />
                  )
                }
              }
            }
        });
        setOrderRender(orderComponents);
      }
    
      
        
  },[orders, nameSelection, statusChange, singleSelections, modalShow, showNotSubmitted])

  const handleShowNotSubmittedChange = ()=>{
    setShowNotSubmitted(!showNotSubmitted);
  }


    return(
    <Fragment>
    {modalShow && <ViewOrderNotSubmitted id={currentId} onClose={onCloseHandler} returnClick={handleViewReturn} deleteClick={handleViewDelete}/>}
    <Card className={`${classes.ordersCard} ${classes.notPrintable}`}>
    <h2 className={classes.notPrintable}>Πρόχειρο</h2>
    {deleteModal && <DeletePermanently id={currentId} yesClick={handleDeleteYes} noClick={handleDeleteNo}/>}
    {returnModal && <EditNotSubmitted id={currentId} yesClick={handleReturnYes} noClick={handleReturnNo}/>}
    <Fragment>
    <div className={classes.switchesContainer}>
      <div className={classes.switchContainer}>
        <p>Παραγγελίες Υπαλλήλου</p>
        <label className={`${classes.switch} ${classes.double}`}>
          <input type="checkbox" checked={showNotSubmitted} onChange={handleShowNotSubmittedChange}/>
          <span className={classes.slider} />
        </label>
        <p>Μη ολοκληρωμένες Παραγγελίες</p>
      </div>
    </div>
    <div className={classes.searchBlock}>
    <Typeahead
          className={classes.typeaheadSelect}
          id="basic-typeahead-single"
          labelKey="label"
          onChange={setSingleSelections}
          options={options}
          placeholder="Αναζήτηση Πελάτη ή Κωδικού Προϊόντος:"
          selected={singleSelections}
        />
    <Button onClick={handleClearClick}>Καθαρισμός</Button>
    </div>
    <table>
      <thead>
        <tr>
          <th>#</th>
          <th>Όνομα</th>
          <th>Κόστος</th>
          <th>Ημερομηνία Δημιουργίας</th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
      {orderRender}
      </tbody>
    </table>
    </Fragment>
  
    </Card>   
    </Fragment>
    )
}


export default OrdersNotSubmitted;