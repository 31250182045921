import React,{useState,useEffect,Fragment} from 'react';
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";
import {useCookies} from 'react-cookie';
import Modal from '../UI/Modal';
import Input from '../UI/Input';
import Button from '../UI/Button';
import OrderContext from '../../store/order-context'
import classes from '../NewOrder/NewOrderProducts/Roller.module.css';
import axios from 'axios';
import { Typeahead } from 'react-bootstrap-typeahead'; 

const RollerEdit = (props) =>{
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const [typeSelection,setTypeSelection]=useState('0');
  const [companies, setCompanies]=useState('');
  const [companySelection, setCompanySelection] = useState([]);
  const [companyDropdown, setCompanyDropdown] = useState('');
  const [sku, setSku]=useState('');
  const [quantity, setQuantity]=useState('1');
  const [squarePrice, setSquarePrice]=useState('');
  const [discount, setDiscount] = useState(false);
  const [discountPrice, setDiscountPrice] = useState('');
  const [totalPriceDiscount, setTotalPriceDiscount]=useState('');
  const [windowWidth, setWindowWidth]=useState('');
  const [windowHeight, setWindowHeight]=useState('');
  const [installPosition, setInstallPosition]=useState('0');
  const [wayOfOpening, setWayOfOpening]=useState('0');
  const [obstacle, setObstacle]=useState('0');
  const [distance, setDistance]=useState('');
  const [aboveWindow, setAboveWindow] = useState('');
  const [suggestedWidth, setSuggestedWidth]=useState('');
  const [suggestedHeight, setSuggestedHeight]=useState('');
  const [rollerWidth, setRollerWidth]=useState('');
  const [rollerHeight, setRollerHeight]=useState('');
  const [support, setSupport]=useState('0');
  const [controllerPlacement, setControllerPlacement]=useState('0');
  const [controllerLengthPlacement, setControllerLengthPlacement]=useState('0');
  const [screwType, setScrewType]=useState('Touvlo')
  const [windowHandle, setWindowHandle]=useState('0');
  const [windowHandleRoller, setWindowHandleRoller]=useState('0');
  const [freeText,setFreeText]=useState('');

  const [totalPrice,setTotalPrice]=useState('');
  const [installation, setInstallation]=useState('oxi');
  const [installPrice, setInstallPrice]=useState('');
  const [manualInstallPrice, setManualInstallPrice] = useState('');

  const [errorMessage, setErrorMessage] =useState('');

  const [isLoaded, setIsLoaded] = useState(false);
  const [productInfo, setProductInfo] = useState('');
  const [productOptions, setProductOptions] = useState('');
  const [options,setOptions]=useState({id:'',label:''});

  useEffect(() => {
    setIsLoaded(true);
    }, []);

  useEffect(() => {
    console.log('proped id is :' +props.id);
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
    };
    axios.post('https://api.orders.e-interior.gr/api/general/init',{
      key: cookies.Token},
      config
    )
    .then(function (response) {
      setCompanies(response.data[0].companies);

    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
      console.log(error);
    });
    axios.post('https://api.orders.e-interior.gr/api/product/'+props.id,{
      key: cookies.Token},
      config
    )
    .then(function (response) {
      console.log(response.data);
      setProductInfo(response.data)
      setProductOptions(JSON.parse(response.data.product_options))
    })
    .catch(function (error) {
      console.log(error);
    });
  },[])

  useEffect(()=>{
    let typeString ='';
    switch(productInfo.product_type){
    case "Μονό roller":
      typeString='single'
      break;
    case "Διπλό ζέβρα roller":
      typeString='double'
      break;
    }

    let installPositionString = '';
    switch(productOptions.installPosition){
      case 'Έξω':
        installPositionString = 'outside';
        break;
      case 'Μέσα':
        installPositionString = 'inside';
        break;
      default:
        installPositionString = '';
        break;
    }

    let wayOfOpeningString = '';
    switch(productOptions.wayOfOpening){
      case '':
        wayOfOpeningString = '0';
        break;
      case 'Ανοιγόμενο':
        wayOfOpeningString = 'open';
        break;
      case 'Συρόμενο':
        wayOfOpeningString = 'slide';
        break;
      default:
        wayOfOpeningString = '';
        break;
    }
    let supportString = '';
    switch(productOptions.support){
      case '0':
        supportString = '0';
        break;
      case 'Οροφή':
        supportString = 'Roof';
        break;
      case 'Τοίχος':
        supportString = 'Wall';
        break;
      default:
        supportString = '0';
        break;
    }

    let controllerString = '';
    switch(productOptions.controllerPlacement){
      case '0':
        controllerString = '0';
        break;
      case 'Αριστερά':
        controllerString = 'left';
        break;
      case 'Δεξιά':
        controllerString = 'right';
        break;
      default:
        controllerString = '0';
        break;
    }

    let controllerLengthString = '';
    switch(productOptions.controllerLengthPlacement){
      case 'Δεν έχω προτίμηση':
        controllerLengthString = '0';
        break;
      case '0.80m':
        controllerLengthString = '0.80';
        break;
      case '1.00m':
        controllerLengthString = '1.00';
        break;
      case '1.30m':
        controllerLengthString = '1.30';
        break;
      case '1.50m':
        controllerLengthString = '1.50';
        break;
      case '2.00m':
        controllerLengthString = '2.00';
        break;
      case '2.50m':
        controllerLengthString = '2.50';
        break;
      case '3.00m':
        controllerLengthString = '3.00';
        break;
      default:
        controllerLengthString = '0';
        break;
    }

    let windowHandleString=''
    let windowHandleRollerString=''
    console.log(productOptions.windowHandle);
    switch(productOptions.windowHandle){
      case "ΟΧΙ - Περιτύλιξη κοντά":
        windowHandleString = "oxi";
        windowHandleRollerString = "Κοντά";
        break;
      case "ΟΧΙ - Περιτύλιξη Κοντά":
        windowHandleString = "oxi";
        windowHandleRollerString = "Κοντά";
        break;
      case "ΟΧΙ - Περιτύλιξη Μακριά":
        windowHandleString = "oxi";
        windowHandleRollerString = "Μακριά";
        break;
      case "ΝΑΙ - Περιτύλιξη μακριά":
        windowHandleString = "nai";
        windowHandleRollerString = "Μακριά";
        break;
      case "ΝΑΙ - Περιτύλιξη Μακριά":
        windowHandleString = "nai";
        windowHandleRollerString = "Μακριά";
        break;
      case "ΝΑΙ - Περιτύλιξη Κοντά":
        windowHandleString = "nai";
        windowHandleRollerString = "Κοντά";
        break;
      default:
        windowHandleString = "oxi";
        break;
    }

    let screwTypeString=''
    switch(productOptions.screwType){
    case 'Για τούβλο/μπετό':
      screwTypeString="Touvlo";
      break;
    case 'Για γυψοσανίδα':
      screwTypeString="Sanida";
      break;
    case 'Για αλουμίνιο (τρυπανόβιδα)':
      screwTypeString="Alouminio";
      break;
    }

    setTypeSelection(typeString);
    setCompanySelection((productInfo.manufacturer && productInfo.manufacturer !=0)  ? [productInfo.manufacturer]:[]);
    setSku(productInfo.code);
    setQuantity(productOptions.quantity ? productOptions.quantity:'1');
    setSquarePrice(productOptions.squarePrice ? productOptions.squarePrice:'');
    setDiscount(productOptions.discount ? productOptions.discount:'');
    setDiscountPrice(productOptions.discountPrice ? productOptions.discountPrice:'');
    setTotalPriceDiscount(productOptions.totalPriceDiscount ? productOptions.totalPriceDiscount:'');
    setWindowWidth(productOptions.windowWidth ? productOptions.windowWidth:'');
    setWindowHeight(productOptions.windowHeight ? productOptions.windowHeight:'');
    setInstallPosition(installPositionString);
    setWayOfOpening(wayOfOpeningString);
    setObstacle(productOptions.obstacle ? productOptions.obstacle:'0');
    setDistance(productOptions.distance ? productOptions.distance:'');
    setAboveWindow(productOptions.aboveWindow ? productOptions.aboveWindow:'');
    setRollerWidth(productOptions.rollerWidth ? productOptions.rollerWidth:'');
    setRollerHeight(productOptions.rollerHeight ? productOptions.rollerHeight:'');
    setSupport(supportString);
    setControllerPlacement(controllerString);
    setControllerLengthPlacement(controllerLengthString);
    setScrewType(screwTypeString)
    setWindowHandle(windowHandleString.length>0?windowHandleString:'0');
    setWindowHandleRoller(windowHandleRollerString.length>0?windowHandleRollerString:'0');
    setFreeText(productOptions.freeText ? productOptions.freeText:'');
    setTotalPrice(productOptions.totalPrice ? productOptions.totalPrice:'');
    setInstallation(productOptions.installation ? productOptions.installation:'oxi');
    setInstallPrice(productOptions.installPrice ? productOptions.installPrice:'');
    setManualInstallPrice(productOptions.installPrice ? productOptions.installPrice/productOptions.quantity:'')

  },[productOptions])

  const handleFocus = (event) => {
    const { name, value } = event.target;
    if (value){
    const inputValue = parseFloat(value);

    if (name === 'windowWidth') {
      setWindowWidth(inputValue);
    } else if (name === 'windowHeight') {
      setWindowHeight(inputValue);
    } else if (name === 'rollerWidth') {
      setRollerWidth(inputValue);
    } else if (name === 'rollerHeight') {
      setRollerHeight(inputValue);
    }
  }
  }
  const handleBlur = (event) => {
    const { name, value } = event.target;
    if (value){
    const inputValue = parseFloat(value);
    const formattedValue = inputValue.toFixed(3) + 'm';

    if (name === 'windowWidth') {
      setWindowWidth(formattedValue);
    } else if (name === 'windowHeight') {
      setWindowHeight(formattedValue);
    } else if (name === 'rollerWidth') {
      setRollerWidth(formattedValue);
    } else if (name === 'rollerHeight') {
      setRollerHeight(formattedValue);
    }
  }
  }

  const typeSelectionHandler = (event) =>{
    setTypeSelection(event.target.value);
  }
  const companySelectionHandler = (event) =>{
    setCompanySelection(event.target.value);
  }
  const skuChangeHandler = (event) =>{
    setSku(event.target.value);
  }
  const quantityChangeHandler = (event)=>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    value = value.trim();
    if (value === '') {
      setQuantity(''); // Set quantity as an empty string if input is empty
    } else {
      value = parseInt(value);
      if (isNaN(value)) {
        setQuantity(''); // Set quantity as an empty string if value is NaN
      } else {
        setQuantity(value);
      }
    }
  }
  const squarePriceChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setSquarePrice(value);
    }
  }
  const discountChangeHandler = (event) =>{
    if (discount){
      setDiscount(false);
    }else{
      setDiscount(true);
    }
  }
  const discountPriceChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setDiscountPrice(value);
    }
  }
  const rollerWidthChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setRollerWidth(value);
    }
  }
  const windowWidthChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setWindowWidth(value);
    }
  }
  const windowHeightChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setWindowHeight(value);
    }
  }
  const wayOfOpeningHandler = (event) =>{
    setWayOfOpening(event.target.value);
  }
  const installPositionHandler = (event) =>{
    if (event.target.value=="inside"){
      setWayOfOpening("slide")
      setObstacle("No")
    }
    setInstallPosition(event.target.value);
  }
  const obstacleHandler = (event) =>{
    setObstacle(event.target.value);
  }
  const distanceChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setDistance(value);
    }
  }
  useEffect (()=>{
    if (obstacle === "Yes"){
      setSuggestedWidth(Math.round((parseFloat(windowWidth)+parseFloat(distance/100)*2)*100)/100);
      setSuggestedHeight(Math.round((parseFloat(windowHeight)+0.05+parseFloat(aboveWindow/100))*100)/100);
    }else if (obstacle === "No" && installPosition != "inside"){
      setSuggestedWidth(Math.round((parseFloat(windowWidth)+0.20)*100)/100);
      setSuggestedHeight(Math.round((parseFloat(windowHeight)+0.05+parseFloat(aboveWindow/100))*100)/100);
    }else if (installPosition === "inside"){
      setSuggestedWidth(Math.round((parseFloat(windowWidth)-0.02)*100)/100);
      setSuggestedHeight(Math.round((parseFloat(windowHeight)-0.02)*100)/100);
    }
  },[obstacle, installPosition,windowHeight,windowWidth,distance,aboveWindow])
  const rollerHeightChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setRollerHeight(value);
    }
  }
  const aboveWindowChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setAboveWindow(value);
    }
  }
  const controllerPlacementHandler = (event) =>{
    setControllerPlacement(event.target.value);
  }
  
  const controllerLengthPlacementHandler = (event) =>{
    setControllerLengthPlacement(event.target.value);
  }

  const screwTypeHandler = (event) =>{
    setScrewType(event.target.value);
  }

  const supportHandler = (event) =>{
    setSupport(event.target.value);
  }
  const windowHandleHandler = (event) =>{
    setWindowHandle(event.target.value);
  }
  const windowHandleRollerHandler = (event) =>{
    setWindowHandleRoller(event.target.value);
  }
  const freeTextChangeHandler = (event) =>{
    setFreeText(event.target.value);
  }
  const installationHandler = (event) =>{
    setInstallation(event.target.value);
  }
  const installationPriceChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setInstallPrice(value);
      setManualInstallPrice(value);
    }
  }

  useEffect(()=>{
    setTotalPrice(Math.round(((parseFloat(Math.max(parseFloat(rollerHeight),1))*parseFloat(Math.max(parseFloat(rollerWidth),1))*squarePrice))*quantity*100)/100);
  },[rollerWidth,rollerHeight,squarePrice, quantity])

  const handleSaveClick = () =>{
  if ( companySelection!='0'&&sku!='0'&&typeSelection!='0'&&squarePrice!='0'&&
    windowWidth!='0'&&windowHeight!='0'){
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
    };
      let installPositionString=''
        switch(installPosition){
          case "outside":
            installPositionString = "Έξω";
            break;
          case "inside":
            installPositionString = "Μέσα";
            break;
          default:
            installPositionString = "";
            break;
        }
        let wayOfOpeningString=''
        switch(wayOfOpening){
          case "0":
            wayOfOpeningString = "";
            break;
          case "open":
            wayOfOpeningString = "Ανοιγόμενο";
            break;
          case "slide":
            wayOfOpeningString = "Συρόμενο";
            break;
          default:
            wayOfOpeningString = "";
            break;
        }
        let supportString=''
        switch(support){
          case "0":
            supportString = "0";
            break;
          case "Roof":
            supportString = "Οροφή";
            break;
          case "Wall":
            supportString = "Τοίχος";
            break;
          default:
            supportString = "0";
            break;
        }
        let controllerString=''
        switch(controllerPlacement){
          case "0":
            controllerString = "0";
            break;
          case "left":
            controllerString = "Αριστερά";
            break;
          case "right":
            controllerString = "Δεξιά";
            break;
          default:
            controllerString = "0";
            break;
        }
        let controllerLengthString='';
        switch(controllerLengthPlacement){
          case "0":
            controllerLengthString = "Δεν έχω προτίμηση";
            break;
          case "0.80":
            controllerLengthString = "0.80m";
            break;
          case "1.00":
            controllerLengthString = "1.00m";
            break;
          case "1.30":
            controllerLengthString = "1.30m";
            break;
          case "1.50":
            controllerLengthString = "1.50m";
            break;
          case "2.00":
            controllerLengthString = "2.00m";
            break;
          case "2.50":
            controllerLengthString = "2.50m";
            break;
          case "3.00":
            controllerLengthString = "3.00m";
            break;
          default:
            controllerLengthString = "Δεν έχω προτίμηση";
            break;
        }
        let windowHandleString=''
        switch(windowHandle){
          case "oxi":
            windowHandleString = "ΟΧΙ";
            break;
          case "nai":
            windowHandleString = "ΝΑΙ";
            break;
          default:
            windowHandleString = "ΟΧΙ";
            break;
        }
        let screwTypeString=''
        switch(screwType){
        case "Touvlo":
          screwTypeString='Για τούβλο/μπετό';
          break;
        case "Sanida":
          screwTypeString='Για γυψοσανίδα';
          break;
        case "Alouminio":
          screwTypeString='Για αλουμίνιο (τρυπανόβιδα)';
          break;
        }
    windowHandleString+=' - Περιτύλιξη '+ (windowHandleRoller!=='0'?windowHandleRoller:'Μακριά');
    console.log(windowHandleString);

    const productOptions = JSON.stringify({
        quantity: quantity,
        squarePrice: squarePrice,
        discount:discount,
        discountPrice: discount ? discountPrice:'',
        totalPriceDiscount: discount ? totalPriceDiscount:'',
        windowHeight: windowHeight,
        windowWidth: windowWidth,
        installPosition: installPositionString,
        wayOfOpening: wayOfOpeningString,
        obstacle: obstacle,
        distance: distance,
        aboveWindow: aboveWindow,
        rollerWidth: rollerWidth,
        rollerHeight: rollerHeight,
        support: supportString,
        controllerPlacement: controllerString,
        controllerLengthPlacement: controllerLengthString,
        windowHandle: windowHandleString,
        screwType: screwTypeString,
        freeText: freeText,
        totalPrice: totalPrice,
        installation: installation,
        installPrice: installPrice,
      })
    let typeString ='';
    switch(typeSelection){
    case 'single':
      typeString="Μονό roller"
      break;
    case 'double':
      typeString="Διπλό ζέβρα roller"
      break;
    }
    axios.post('https://api.orders.e-interior.gr/api/product/update',{
      key: cookies.Token,
      product_id:props.id,
      product_type: typeString,
      manufacturer: companySelection[0].label,
      code: sku,
      product_options: productOptions,
      },
      config
    )
    .then(function (response) {
      console.log(response.data);
      props.onSave({data:response.data})

    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
      console.log(error);
    });
  }else{
    setErrorMessage('Λείπουν υποχρεωτικά πεδία');
  }
  }

useEffect(()=>{
      if (companies){
        setOptions(companies.map((key) => ({
                id: key,
                label: `${key}`,
              })))}
    }, [companies])

useEffect(()=>{
      setTotalPriceDiscount(Number(discountPrice)*Number(quantity));
    },[discountPrice,quantity])

  useEffect (()=>{
    if (manualInstallPrice>0){
      setInstallPrice(manualInstallPrice*quantity);
    }
  },[quantity])

  return(
    <Modal className={classes.singleModal}>
    <h2>Παραγγελία Roller</h2>
    <div>
    <div className={classes.searchBlock}>
      <span>
        <label htmlFor="type">Τύπος Roller: </label>
        <select id="type" name="type" value={typeSelection} onChange={typeSelectionHandler}>
          <option value="0"> </option>
          <option value="single">Μονό</option>
          <option value="double">Διπλό Zebra</option>
        </select>
      </span>
    </div>
      <div className={classes.searchBlock}>
        <span>
          <label htmlFor="type">Εταιρεία: </label>
            {options && 
            <Typeahead
                className={classes.typeaheadSelect}
                id="basic-typeahead-single"
                labelKey="label"
                onChange={setCompanySelection}
                options={options}
                placeholder=""
                defaultSelected={companySelection}
                selected={companySelection}
            />}
        </span>
      </div>
      <div className={classes.textarea}>
      <label htmlFor="sku">Κωδικός/Περιγραφή: </label>
      <textarea id="sku" rows="2" value={sku} onChange={skuChangeHandler}></textarea>
      </div>
      
      <Input
      onChange={squarePriceChangeHandler}
      label="Τιμή Τεραγωνικού:"
      isPrice="true"
      input={{
        id: 1,
        type: 'text',
        value: squarePrice
      }} />
      <Input
      onChange={windowWidthChangeHandler}
      onBlur={handleBlur}
      onFocus={handleFocus}
      label="Πλάτος Παραθύρου (m):"
      input={{
        id: 1,
        name: 'windowWidth',
        type: 'text',
        value: windowWidth
      }} />
      <Input
      onChange={windowHeightChangeHandler}
      onBlur={handleBlur}
      onFocus={handleFocus}
      label="Ύψος Παραθύρου (m):"
      input={{
        id: 1,
        name: 'windowHeight',
        type: 'text',
        value: windowHeight
      }} />
      <div className={classes.searchBlock}>
        <span>
          <label htmlFor="type">Μέρος τοποθέτησης: </label>
          <select id="type" name="type" value={installPosition} onChange={installPositionHandler}>
            <option value="0"> </option>
            <option value="inside">Μέσα</option>
            <option value="outside">Έξω</option>
          </select>
        </span>
      </div>
      {installPosition === "outside" &&
      <Fragment>
      <div className={classes.searchBlock}>
        <span>
          <label htmlFor="type">Ανοιγόμενο ή Συρόμενο: </label>
          <select id="type" name="type" value={wayOfOpening} onChange={wayOfOpeningHandler}>
            <option value="0"> </option>
            <option value="open">Ανοιγόμενο</option>
            <option value="slide">Συρόμενο</option>
          </select>
        </span>
      </div>
      </Fragment>}
      { wayOfOpening !=="0" &&
      <Fragment>
      {installPosition === "outside" &&
      <div className={classes.searchBlock}>
        <span>
          <label htmlFor="type">Υπάρχει εμπόδιο αριστερά ή δεξιά από το παράθυρο σε απόσταση μικρότερη των 20cm; </label>
          <select id="type" name="type" value={obstacle} onChange={obstacleHandler}>
            <option value="0"></option>
            <option value="Yes">Ναι</option>
            <option value="No">Όχι</option>
          </select>
        </span>
      </div>}
      { (obstacle !=="0"|| installPosition === "inside") &&
      <Fragment>
      {obstacle === "Yes" &&
      <Fragment>
      <Input
      onChange={distanceChangeHandler}
      label="Απόσταση έως εμπόδιο (εκ):"
      input={{
        id: 1,
        type: 'text',
        value: distance
      }} /> {distance && <p className={classes.hint} style={{textAlign:'right',marginRight:'30px'}}>{distance/100}m</p>}</Fragment>}
      <div>
      <Input
      onChange={rollerWidthChangeHandler}
      onBlur={handleBlur}
      onFocus={handleFocus}
      label="Πλάτος Roller (m):"
      input={{
        id: 1,
        name: 'rollerWidth',
        type: 'text',
        value: rollerWidth
      }} />
      <p className={classes.hint}>{(windowWidth.length>0)&&'Προτεινόμενο Πλάτος: '+suggestedWidth+ 'm'}</p>
      </div>
      {!(installPosition==="inside" )&&
      <Input
      onChange={aboveWindowChangeHandler}
      label="Πόσο πάνω από το παράθυρο θα τοποθετηθεί το ρόλερ (cm);"
      input={{
        id: 1,
        type: 'text',
        value: aboveWindow
      }} />}
      <div>
      <Input
      onChange={rollerHeightChangeHandler}
      onBlur={handleBlur}
      onFocus={handleFocus}
      label="Ύψος roller (m):"
      input={{
        id: 1,
        name: 'rollerHeight',
        type: 'text',
        value: rollerHeight
      }} />
      <p className={classes.hint}>{(windowHeight.length>0)&& 'Προτεινόμενο ύψος Roller: '+suggestedHeight+ 'm'}</p>
      </div>
      <div className={classes.searchBlock}>
        <span>
          <label htmlFor="type">Στήριξη σε: </label>
          <select id="type" name="type" value={support} onChange={supportHandler}>
            <option value="0"></option>
            <option value="Roof">Οροφή</option>
            {!(installPosition==="inside" )&&<option value="Wall">Τοίχο</option>}
          </select>
        </span>
      </div>
      <div className={classes.searchBlock}>
        <span>
          <label htmlFor="controllerPlacement">Προεξέχει κάποια λαβή στο παράθυρο; </label>
          <select id="controllerPlacement" name="controllerPlacement" value={windowHandle} onChange={windowHandleHandler}>
            <option value="oxi">ΟΧΙ</option>
            <option value="nai">ΝΑΙ</option>
          </select>
        </span>
      </div>
      <div className={classes.searchBlock}>
        <span>
          <label htmlFor="controllerPlacement">Περιτύλιξη: </label>
          <select id="controllerPlacement" name="controllerPlacement" value={windowHandleRoller} onChange={windowHandleRollerHandler}>
            <option value="Μακριά">Mακριά</option>
            <option value="Κοντά">Κοντά</option>
          </select>
        </span>
      </div>
      <div className={classes.searchBlock}>
        <span>
          <label htmlFor="controllerPlacement">Θέση χειριστηρίων: </label>
          <select id="controllerPlacement" name="controllerPlacement" value={controllerPlacement} onChange={controllerPlacementHandler}>
            <option value="0"> </option>
            <option value="left">Αριστερά</option>
            <option value="right">Δεξιά</option>
          </select>
        </span>
      </div>
      <div className={classes.searchBlock}>
        <span>
          <label htmlFor="controllerLengthPlacement">Μήκος χειριστηρίων: </label>
          <select id="controllerLengthPlacement" name="controllerLengthPlacement" value={controllerLengthPlacement} onChange={controllerLengthPlacementHandler}>
            <option value="0">Δεν έχω προτίμηση</option>
            <option value="0.80">0.80m</option>
            <option value="1.00">1.00m</option>
            <option value="1.30">1.30m</option>
            <option value="1.50">1.50m</option>
            <option value="2.00">2.00m</option>
            <option value="2.50">2.50m</option>
            <option value="3.00">3.00m</option>
          </select>
        </span>
      </div>
      <div className={classes.searchBlock}>
        <span>
          <label htmlFor="screwType">Ούπα/Βίδες: </label>
          <select id="screwType" name="screwType" onChange={screwTypeHandler} value={screwType}>
            <option value="Touvlo">Για τούβλο/μπετό</option>
            <option value="Sanida">Για γυψοσανίδα</option>
            <option value="Alouminio">Για αλουμίνιο (τρυπανόβιδα)</option>
          </select>
        </span>
      </div>
      <div className={classes.textarea}>
      <label htmlFor="sku">Σημειώσεις: </label>
      <textarea id="sku" rows="2" value={freeText} onChange={freeTextChangeHandler}></textarea>
      </div>
      <div>
        <p>Τιμή: {totalPrice} €</p>
      </div>
        <div>
          <Button className={classes.discountBtn} onClick={discountChangeHandler}>Έκπτωση;</Button>
          {!discount && <p></p>}
          {discount && 
          <Fragment>
          <Input
          onChange={discountPriceChangeHandler}
          label="Τιμή με έκπτωση:"
          isPrice="true"
          input={{
            id: 1,
            type: 'text',
            value: discountPrice
          }} />
          <p>Σύνολο με έκπτωση: {totalPriceDiscount}€</p>
          </Fragment>
          }
        </div>
      <div className={classes.searchBlock}>
        <span>
          <label htmlFor="installation">Τοποθέτηση: </label>
          <select id="installation" value={installation} name="installation" onChange={installationHandler}>
            <option value="oxi">Όχι</option>
            <option value="nai">Ναι</option>
          </select>
        </span>
        { installation ==="nai" &&
        <p style={{marginTop:10+'px'}}>
        <Input
        onChange={installationPriceChangeHandler}
        label="Κόστος:"
        input={{
          id: 1,
          type: 'text',
          value: installPrice
        }} /></p>}
      </div>
      
      </Fragment>}
      </Fragment>}

      <Input
      onChange={quantityChangeHandler}
      label="Ποσότητα:"
      input={{
        id: 1,
        type: 'text',
        value: quantity
      }} />
      </div>

      <div className={classes.actions}>
        <button className={classes[`button--alt`]} onClick={props.onClose}>Πίσω</button>
        <button className={classes[`button--alt`]} onClick={handleSaveClick}>Αποθήκευση</button>
      </div>
    </Modal>
  )

}

export default RollerEdit;
