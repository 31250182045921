import React, {useState,useEffect, Fragment} from 'react';
import Card from '../UI/Card';
import classes from './Deficiencies.module.css'
import SingleDeficiencies from './SingleDeficiencies'
import DeficienciesModal from './DeficienciesModal';
import ConfirmCheckAll from './ConfirmCheckAll';
import ConfirmUncheckAll from './ConfirmUncheckAll'
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";
import {useCookies} from 'react-cookie';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import Button from '../UI/Button';

const Deficiencies = () =>{

  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const [products,setProducts] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [productUpdate, setProductUpdate] = useState(false);
  const [companies, setCompanies] = useState('');
  const [currentCompanies, setCurrentCompanies] = useState('');
  const [currentCategories, setCurrentCategories] = useState('');
  const [companySelection, setCompanySelection] = useState("0");
  const [catSelection, setCatSelection] = useState("0");
  const [showOrdered, setShowOrdered] = useState(false);
  const [categoryModal, setCategoryModal] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkAllModal, setCheckAllModal] = useState(false);
  const [uncheckAllModal, setUncheckAllModal] = useState(false);
  const [generalCheckbox, setGeneralCheckbox] = useState(false);
  useEffect(() => {
    if (!cookies.Token) {
      navigate("/user-login");
    }
  });
  useEffect(() => {
    setIsLoaded(true);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.orders.e-interior.gr/api/verify',
      headers: { 
        'Accept': 'application/json', 
        'Authorization': 'Bearer '+cookies.Token, 
      },
    };

    axios.request(config)
    .then((response) => {
      if (response.data.message!=="Success"){
        removeCookies('Name', { path: '/' });
        removeCookies('Token', { path: '/' });
        navigate("/user-login");
      };
    })
    .catch((error) => {
      removeCookies('Name', { path: '/' });
      removeCookies('Token', { path: '/' });
      navigate("/user-login");
    });
}, []);
  useEffect(() => {
    let data = new FormData();

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.orders.e-interior.gr/api/ellipseis',
      headers: { 
        'Accept': 'application/json', 
        'Authorization': 'Bearer '+cookies.Token, 
      },
      data : data
    };

      axios.request(config)
      .then((response) => {
        console.log(response.data.eshopProducts);
        setProducts(response.data.eshopProducts.sort(function(a,b) {
        if (a.checked < b.checked){
          return -1;
        }else if(a.checked > b.checked){
          return 1;
        }else{
          return 0;
        }
      }));
      })
      .catch((error) => {
        if (error.response.data.message==="Unauthenticated."){
        removeCookies('Name', { path: '/' });
        removeCookies('Token', { path: '/' });
        navigate("/user-login");
      }
        console.log(error)});
  },[isLoaded, productUpdate, checkAll])

  useEffect(()=>{
    setCurrentCompanies([...new Set(products.map(item => item.manufacturer_name))]);
    setCurrentCategories([...new Set(products.map(item => item.cat_name))]);

  },[products])

  useEffect(()=>{
    const orderedProductList = products.filter((product) => {
        return (
          parseInt(product.active) === 1 &&
          (companySelection === "0" || product.manufacturer_name === companySelection) &&
          (catSelection === "0" || product.cat_name === catSelection)
        );
      })
    const areAllProductsChecked = orderedProductList.every((product) => product.checked);
    setGeneralCheckbox(areAllProductsChecked);
  },[products,companySelection,catSelection])
  const handleChooseCategoriesClick = (event) =>{
    setCategoryModal(true);
  }

  const handleCancelClick = (event) =>{
    setProductUpdate(!productUpdate);
    setCategoryModal(false);
  }

  const companySelectionHandler = (event) =>{
    setCompanySelection(event.target.value);
  }
  const catSelectionHandler = (event) =>{
    setCatSelection(event.target.value);
  }
  const handleOrdered = ()=>{
    setProductUpdate(!productUpdate);
  }
  const handleCheckedUpdate = (e) =>{
    const updateProducts = products.map((item) => {
      if (parseInt(item.id_product) === parseInt(e.id)) {
        return {
          ...item,
          checked: e.checked?1:0,
        };
      }
      return item;
    });

    // setProducts(updateProducts.sort(function(a,b) {
    //     if (a.checked < b.checked){
    //       return -1;
    //     }else if(a.checked > b.checked){
    //       return 1;
    //     }else{
    //       return 0;
    //     }
    //   }));
    setProductUpdate(!productUpdate);
  }

    const handleQuantityUpdate = (e) =>{
      const updateProducts = products.map((item) => {
        if (parseInt(item.id_product) === parseInt(e.id)) {
          return {
            ...item,
            ordered_quantity: e.ordered_quantity,
          };
        }
        return item;
      });
      setProducts(updateProducts);
  }

  const handleGeneralCheckboxClick = (event) =>{
    // setGeneralCheckbox(event.target.value);
    if (event.target.checked){
      setCheckAllModal(true);
    }else{
      setUncheckAllModal(true);
    }
  }
  const handleCheckAllYes = (e) =>{
    const orderedProductList = products.filter((product) => {
      return (
        parseInt(product.active) === 1 &&
        (companySelection === "0" || product.manufacturer_name === companySelection) &&
        (catSelection === "0" || product.cat_name === catSelection) &&
        (product.ordered_quantity !== 0)
      );
    }).map((product) => ({
          product_id: product.id_product,
          checked: 1,
          quantity: product.quantity,
          ordered_quantity: product.ordered_quantity?parseInt(product.ordered_quantity):0,
      }));

      let data = new FormData();
      data.append('products', JSON.stringify(orderedProductList));

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://api.orders.e-interior.gr/api/ellipseis/product-list/save',
        headers: { 
          'Accept': 'application/json', 
          'Authorization': 'Bearer '+cookies.Token, 
        },
        data : data
      };

      axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setCheckAll(!checkAll);
        setCheckAllModal(false);
      })
      .catch((error) => {
        console.log(error);
      });
  
  }

  const handleUncheckAllYes = (e) =>{
      const orderedProductList = products.filter((product) => {
        return (
          parseInt(product.active) === 1 &&
          (companySelection === "0" || product.manufacturer_name === companySelection) &&
          (catSelection === "0" || product.cat_name === catSelection)
        );
      }).map((product) => ({
            product_id: product.id_product,
            checked: 0,
            quantity: product.quantity,
            ordered_quantity: 0,
        }));

    
    console.log(orderedProductList);
    let data = new FormData();
    data.append('products', JSON.stringify(orderedProductList));

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.orders.e-interior.gr/api/ellipseis/product-list/save',
      headers: { 
        'Accept': 'application/json', 
        'Authorization': 'Bearer '+cookies.Token, 
      },
      data : data
    };

    axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      setCheckAll(!checkAll);
      setUncheckAllModal(false);
    })
    .catch((error) => {
      console.log(error);
    });
  }
  const handleCheckAllNo = (e) =>{
    setCheckAllModal(false);
  }
  const handleUncheckAllNo = (e) =>{
    setUncheckAllModal(false);
  }

  const printList = () => {
    window.print();
  };

  return(
    <Card className={classes.ordersCard}>
    {categoryModal && <DeficienciesModal onClose={handleCancelClick}/>}
    {checkAllModal && <ConfirmCheckAll yesClick={handleCheckAllYes} noClick={handleCheckAllNo}/>}
    {uncheckAllModal && <ConfirmUncheckAll yesClick={handleUncheckAllYes} noClick={handleUncheckAllNo}/>}
    <div id={classes.printable}>
      <h2>Παραγγελία ελλείψεων</h2>
      <div className={classes.printableGrid}>
        <p>Προϊόν</p>
        <p>Κωδικός</p>
        <p>Παραγγελία</p>
      </div>
      <hr/>
      <div className={classes.printableGrid}>
        {products.filter((product) => {
        return (
          parseInt(product.active) === 1 &&
          (companySelection === "0" || product.manufacturer_name === companySelection) &&
          (catSelection === "0" || product.cat_name === catSelection) &&
          (product.checked === 1 || product.checked === true) && 
          (product.ordered_quantity!==0)
        );
      }).map((product) => (
          <Fragment>
          <p>{product.name}</p>
          <p>{product.reference}</p>
          <p>{product.ordered_quantity}</p>
          </Fragment>
        ))}
      </div>
    </div>
    <div id={classes.notPrintable}>
    <h2>Ελλείψεις</h2>
    <Button className={classes.printButton} onClick={printList}>Εκτύπωση Παραγγελίας</Button>
    <div className={classes.selectionContainer}>
      <Button className={classes.chooseCategoriesButton} onClick={handleChooseCategoriesClick}>Επιλογή Κατηγοριών</Button>
      <select id="type" name="type" onChange={companySelectionHandler} value={companySelection}>
            <option value="0"> --- Εταιρεία --- </option>
            {currentCompanies && currentCompanies.map((key)=>{
              return(
                <option value={key}> {key}</option>
              )
            }
          )}
        </select>
        <select id="type" name="type" onChange={catSelectionHandler} value={catSelection}>
            <option value="0"> --- Κατηγορία --- </option>
            {currentCategories && currentCategories.map((key)=>{
              return(
                <option value={key}> {key}</option>
              )
            }
          )}
        </select>
    </div>
    <div className={classes.generalCheckbox}>
      <label>
        <span>Παραγγελία όλων </span> 
      </label>
      <input
          type="checkbox"
          checked={generalCheckbox}
          onChange={handleGeneralCheckboxClick}/>
    </div>
    <table>
      <thead>
        <tr>
          <th>Προϊόν</th>
          <th>Κωδικός</th>
          <th>Κατηγορία</th>
          <th>Κατάστημα</th>
          <th>Παραγγελία</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
      {Array.isArray(products) && products.map((product)=>{
          if(parseInt(product.active)===1&&(companySelection==="0"||product.manufacturer_name===companySelection)&&(catSelection==="0"||product.cat_name===catSelection)){

            return(
              <SingleDeficiencies
              id={product.id_product}
              key={product.id_product}
              code={product.reference}
              product={product.name}
              quantity={product.quantity}
              ordered_quantity={product.ordered_quantity}
              category={product.cat_name}
              checked={product.checked}
              updated_at={product.updated_at}
              onCheckedUpdate={handleCheckedUpdate}
              onQuantityUpdate={handleQuantityUpdate}
              />

              )
          }
        }
      )}
      </tbody>
    </table>
    {products.length===0 && <p>Δεν έχουν επιλεχθεί κατηγορίες ή δεν υπάρχουν προιόντα σε έλλειψη</p>}
    </div>
    </Card>
  )
}
export default Deficiencies;
