import React,{useState,useEffect,Fragment} from 'react';
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";
import {useCookies} from 'react-cookie';
import Modal from '../UI/Modal';
import Input from '../UI/Input';
import Button from '../UI/Button';
import classes from '../NewOrder/NewOrderProducts/CustomProduct.module.css';
import axios from 'axios';
import { Typeahead } from 'react-bootstrap-typeahead'; 

const CustomProductEdit = (props) =>{
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const [companies, setCompanies]=useState('');
  const [companySelection, setCompanySelection] = useState([]);
  const [companyDropdown, setCompanyDropdown] = useState('');
  const [productName, setProductName]=useState('');
  const [price, setPrice]=useState('');
  const [quantity, setQuantity]=useState('1');
  const [totalPrice, setTotalPrice]=useState('');
  const [discount,setDiscount]=useState('');
  const [discountPrice, setDiscountPrice]=useState('');
  const [totalPriceDiscount, setTotalPriceDiscount]=useState('');
  const [notes, setNotes]=useState('');
  const [errorMessage,setErrorMessage]=useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [productInfo, setProductInfo] = useState('');
  const [productOptions, setProductOptions] = useState('');
  const [options,setOptions]=useState({id:'',label:''});


  useEffect(() => {
    setIsLoaded(true);
}, []);

  useEffect(() => {
    console.log('proped id is :' +props.id);
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
    };
    axios.post('https://api.orders.e-interior.gr/api/general/init',{
      key: cookies.Token},
      config
    )
    .then(function (response) {
      setCompanies(response.data[0].companies);

    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
      console.log(error);
    });

    axios.post('https://api.orders.e-interior.gr/api/product/'+props.id,{
      key: cookies.Token},
      config
    )
    .then(function (response) {
      console.log(response.data);
      setProductInfo(response.data)
      setProductOptions(JSON.parse(response.data.product_options))
    })
    .catch(function (error) {
      console.log(error);
    });
  },[])

  useEffect(()=>{
    setTotalPrice(Number(price)*Number(quantity));
  },[price,quantity])

  useEffect(() => {
    setCompanySelection((productInfo.manufacturer && productInfo.manufacturer !=0)  ? [productInfo.manufacturer]:[]);
    setProductName(productInfo.code ? productInfo.code:'');
    setPrice(productOptions.price ? productOptions.price:'');
    setQuantity(productOptions.quantity ? productOptions.quantity:'1');
    setTotalPrice(productOptions.totalPrice ? productOptions.totalPrice:'');
    setDiscount(productOptions.discount ? productOptions.discount:'');
    setDiscountPrice(productOptions.discountPrice ? productOptions.discountPrice:'');
    setTotalPriceDiscount(productOptions.totalPriceDiscount ? productOptions.totalPriceDiscount:'');
    setNotes(productOptions.notes ? productOptions.notes:'');
  },[productOptions])
  const companySelectionHandler = (event) =>{
    setCompanySelection(event.target.value);
  }
  const productNameChangeHandler = (event)=>{
  	setProductName(event.target.value);
  }

  const priceChangeHandler = (event)=>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setPrice(value);
    }

  }
  const quantityChangeHandler = (event)=>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    value = value.trim();
    if (value === '') {
      setQuantity(''); // Set quantity as an empty string if input is empty
    } else {
      value = parseInt(value);
      if (isNaN(value)) {
        setQuantity(''); // Set quantity as an empty string if value is NaN
      } else {
        setQuantity(value);
      }
    }
  }
  const discountChangeHandler = (event) =>{
    if (discount){
      setDiscount(false);
    }else{
      setDiscount(true);
    }
  }
  const discountPriceChangeHandler = (event) =>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setDiscountPrice(value);
    }
  }
  const notesChangeHandler = (event) =>{
  	setNotes(event.target.value);
  }

    const handleSaveClick = (e) =>{
	    if ( companySelection!='0'&&price!='0'){
	      const config = {
	        headers: { 'Authorization': 'Bearer '+cookies.Token}
	      };
	      const productOptions = JSON.stringify({
	        price:price,
          quantity:quantity,
          totalPrice: totalPrice,
          discount:discount,
          discountPrice: discount ? discountPrice:'',
          totalPriceDiscount: discount ? totalPriceDiscount:'',
          notes:notes
	      })

	      axios.post('https://api.orders.e-interior.gr/api/product/update',{
	        key: cookies.Token,
          product_id: props.id,
	        product_type: 'Άλλο είδος ',
	        code: productName,
	        manufacturer: companySelection[0].label,
	        product_options: productOptions,
	        },
	        config
	      )
	      .then(function (response) {
	        console.log(response.data);
	        props.onSave({data:response.data})

	      })
	      .catch(function (error) {
          if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
	        console.log(error);
	        e.preventDefault();
	      });
	  	}else{
      	setErrorMessage('Missing required fields');
    	}
  	}	
    useEffect(()=>{
      if (companies){
        setOptions(companies.map((key) => ({
                id: key,
                label: `${key}`,
              })))}
    }, [companies])

    useEffect(()=>{
      setTotalPriceDiscount(Number(discountPrice)*Number(quantity));
    },[discountPrice,quantity])

  return(
  	<Modal className={classes.singleModal}>
      <h2 className={classes.title}>Παραγγελία Άλλου Είδους</h2>
      {errorMessage}
      
      <Input
      onChange={productNameChangeHandler}
      label="Όνομα Προϊόντος:"
      input={{
        id: 1,
        type: 'text',
        value: productName
      }} />
      <div className={classes.searchBlock}>
        <span>
          <label htmlFor="type">Εταιρεία: </label>
            {options && 
            <Typeahead
                className={classes.typeaheadSelect}
                id="basic-typeahead-single"
                labelKey="label"
                onChange={setCompanySelection}
                options={options}
                placeholder=""
                defaultSelected={companySelection}
                selected={companySelection}
            />}
        </span>
      </div>
      <Input
      onChange={priceChangeHandler}
      label="Τιμή:"
      isPrice="true"
      input={{
        id: 1,
        type: 'text',
        value: price
      }} />
      
      <p>Τιμή Σύνολο: {totalPrice}€</p>
      <div>
      <Button className={classes.discountBtn} onClick={discountChangeHandler}>Έκπτωση;</Button>
      {!discount && <p></p>}
      {discount && 
      <Fragment>
      <Input
      onChange={discountPriceChangeHandler}
      label="Τιμή με έκπτωση:"
      isPrice="true"
      input={{
        id: 1,
        type: 'text',
        value: discountPrice
      }} />
      <p>Σύνολο με έκπτωση: {totalPriceDiscount}€</p>
      </Fragment>
      }
      </div>
      <div className={classes.textarea}>
      <label htmlFor="sku">Σημειώσεις: </label>
      <textarea id="sku" rows="2" value={notes} onChange={notesChangeHandler}></textarea>
      </div>
      <Input
      onChange={quantityChangeHandler}
      label="Ποσότητα:"
      input={{
        id: 1,
        type: 'text',
        value: quantity
      }} />
      <div className={classes.actions}>
        <button className={classes[`button--alt`]} onClick={props.onClose}>Πίσω</button>
        <button onClick={handleSaveClick} className={classes[`button--alt`]}>Αποθήκευση</button>
      </div>
    </Modal>
  )
}
export default CustomProductEdit;