import React,{Fragment,useState,useContext,useEffect} from 'react';
import { Link, Navigate, useNavigate, useLocation} from "react-router-dom";

//New products
import CurtainRod from './NewOrderProductsEmployee/CurtainRod';
import Fabrics from './NewOrderProductsEmployee/Fabric';
import Persides from './NewOrderProductsEmployee/Persides';
import Roller from './NewOrderProductsEmployee/Roller';
import XaliMoketaPlastiko from './NewOrderProductsEmployee/XaliMoketaPlastiko';
import RomanPseudoroman from './NewOrderProductsEmployee/RomanPseudoroman';
import CustomProduct from './NewOrderProductsEmployee/CustomProduct';
import Diadromos from './NewOrderProductsEmployee/Diadromos';

//View Products
import CurtainRodViewEmployee from './ViewProductsEmployee/CurtainRodViewEmployee'
import FabricViewEmployee from './ViewProductsEmployee/FabricViewEmployee'
import PersidesViewEmployee from './ViewProductsEmployee/PersidesViewEmployee'
import RollerViewEmployee from './ViewProductsEmployee/RollerViewEmployee'
import XaliViewEmployee from './ViewProductsEmployee/XaliViewEmployee'
import RomanViewEmployee from './ViewProductsEmployee/RomanViewEmployee'
import CustomProductViewEmployee from './ViewProductsEmployee/CustomProductViewEmployee'
import DiadromosViewEmployee from './ViewProductsEmployee/DiadromosViewEmployee';

//edit products
import CurtainRodEdit from '../EditProducts/CurtainRodEdit'
import FabricEdit from '../EditProducts/FabricEdit'
import PersidesEdit from '../EditProducts/PersidesEdit'
import RollerEdit from '../EditProducts/RollerEdit'
import RomanEdit from '../EditProducts/RomanEdit'
import XaliEdit from '../EditProducts/XaliEdit'
import CustomProductEdit from '../EditProducts/CustomProductEdit';
import DiadromosEdit from '../EditProducts/DiadromosEdit';

import Button from '../UI/Button';
import OrderContext from '../../store/order-context';

import classes from './NewOrderProductEmployee.module.css';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlus, faPencilAlt, faEye, faTrash , faCopy} from '@fortawesome/free-solid-svg-icons'

import {useCookies} from 'react-cookie';
import axios from 'axios';

const plus = <FontAwesomeIcon icon={faPlus} />
const pencil = <FontAwesomeIcon icon={faPencilAlt} />
const view = <FontAwesomeIcon icon={faEye} />
const copy = <FontAwesomeIcon icon={faCopy} />
const trash = <FontAwesomeIcon icon={faTrash} />

const NewOrderProductEmployee = (props) => {
  const orderCtx = useContext(OrderContext);
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const [selection,setSelection]=useState("0");
  const [modalShow,setModalShow]=useState(false);
  const [viewModal, setViewModal]=useState('');
  const [editModal, setEditModal]=useState('');
  const [productList,setProductList]=useState([]);
  const [clientCookie, setClientCookie,removeClientCookie] = useCookies (['client']);
  const [isLoaded,setIsLoaded] = useState(false);

  const [updatedProduct, setUpdatedProduct]=useState('');
  const [totalProductCost, setTotalProductCost]=useState(0);
  const [totalWithDiscount, setTotalWithDiscount]=useState(0);
  const [totalInstallCost, setTotalInstallCost]=useState(0);
  const [discount, setDiscount]=useState(false);


  const selectChangeHandler = (event) =>{
    setSelection(event.target.value);
  }

  useEffect(()=>{
    if (selection !== "0"){
    setModalShow(true);
    }
  },[selection])
  
  const addCloseHandler = () =>{
    setModalShow(false);
    setSelection("0");
  }
  const addSaveHandler = (event) =>{
    setModalShow(false);
    setSelection("0");
  }

  const viewClickHandler = (id,productType) =>{
    if (productType.includes("Ύφασμα")){
      setViewModal(<FabricViewEmployee id={id} onClose={viewCloseHandler}/>)
    }else if (productType.includes("Κουρτινόξυλο")){
      setViewModal(<CurtainRodViewEmployee id={id} onClose={viewCloseHandler}/>)
    }else if (productType.includes("Περσίδες")){
      setViewModal(<PersidesViewEmployee id={id} onClose={viewCloseHandler}/>)
    }else if (productType.includes("roller")){
      setViewModal(<RollerViewEmployee id={id} onClose={viewCloseHandler}/>)
    }else if (productType.includes("χαλί")||productType.includes("Μοκέτα")||productType.includes("Πλαστικό")||productType.includes("Γκαζόν")||productType.includes("Σετ Κρεβατοκάμαρας")){
      setViewModal(<XaliViewEmployee id={id} onClose={viewCloseHandler}/>)
    }else if (productType.includes('Ρόμαν')||productType.includes('Ψευτορόμαν')){
      setViewModal(<RomanViewEmployee id={id} onClose={viewCloseHandler}/>)
    }else if(productType.includes('Διάδρομος')){
      setViewModal(<DiadromosViewEmployee id={id} onClose={viewCloseHandler}/>)
    }else if (productType.includes('Άλλο είδος')){
      setViewModal(<CustomProductViewEmployee id={id} onClose={viewCloseHandler}/>)
    }
  }
  const viewCloseHandler = ()=>{
    setViewModal('');
  }

   const editClickHandler = (id,productType) =>{
    if (productType.includes("Ύφασμα")){
    setEditModal(<FabricEdit id={id} onClose={editCloseHandler} onSave={editCloseHandler}/>)
    }else if (productType.includes("Κουρτινόξυλο")){
      setEditModal(<CurtainRodEdit id={id} onClose={editCloseHandler} onSave={editCloseHandler}/>)
    }else if (productType.includes("Περσίδες")){
      setEditModal(<PersidesEdit id={id} onClose={editCloseHandler} onSave={editCloseHandler}/>)
    }else if (productType.includes("roller")){
      setEditModal(<RollerEdit id={id} onClose={editCloseHandler} onSave={editCloseHandler}/>)
    }else if (productType.includes("χαλί")||productType.includes("Μοκέτα")||productType.includes("Πλαστικό")||productType.includes("Γκαζόν")||productType.includes("Σετ Κρεβατοκάμαρας")){
      setEditModal(<XaliEdit id={id} onClose={editCloseHandler} onSave={editCloseHandler}/>)
    }else if (productType.includes('Ρόμαν')||productType.includes('Ψευτορόμαν')){
      setEditModal(<RomanEdit id={id} onClose={editCloseHandler} onSave={editCloseHandler}/>)
    }else if (productType.includes('Διάδρομος')){
      setEditModal(<DiadromosEdit id={id} onClose={editCloseHandler} onSave={editCloseHandler}/>)
    }else if (productType.includes('Άλλο είδος')){
      setEditModal(<CustomProductEdit id={id} onClose={editCloseHandler} onSave={editCloseHandler}/>)
    }
} 
  const editCloseHandler = ()=>{
    setEditModal('');
  }
  useEffect(() => {
  const config = {
    headers: { 'Authorization': 'Bearer '+'5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp'}
  };
  axios.post('https://api.orders.e-interior.gr/api/products',{
    key: '5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp'},
    config
  )
  .then(function (response) {
    setProductList(response.data);

  })
  .catch(function (error) {
    console.log(error);
  });
  },[modalShow,updatedProduct,editModal])

  useEffect(() =>{
    setIsLoaded(true);
  },[])

  useEffect(()=>{
    let productCost=0;
    let productCostSum=0;
    let installCost=0;
    let installCostSum=0;
    let discountCost=0;
    let discountCostSum=0;
    setDiscount(false)
    setTotalProductCost(0)
    setTotalWithDiscount(0)
    for (let product in productList){
      if (productList[product].order_id==clientCookie.OrderId&&productList[product].status!=="deleted"){
        productCost=JSON.parse(productList[product].product_options).totalPrice
       
        installCost=JSON.parse(productList[product].product_options).installPrice ? Number(JSON.parse(productList[product].product_options).installPrice):0
        productCostSum=productCostSum+Number(productCost)+installCost
        if (JSON.parse(productList[product].product_options).discountPrice||JSON.parse(productList[product].product_options).totalPriceDiscount){
          setDiscount(true);
          if (JSON.parse(productList[product].product_options).totalPriceDiscount){
            discountCost=JSON.parse(productList[product].product_options).totalPriceDiscount;
          } else{
            discountCost=JSON.parse(productList[product].product_options).discountPrice;
          }
          
        }else{
          
          discountCost=productCost
        }
        discountCostSum=discountCostSum+Number(discountCost)+installCost;
        installCostSum=installCostSum+installCost;
      }
    }
    
    setTotalProductCost(Math.round(productCostSum*100)/100);
    setTotalInstallCost(Math.round(installCostSum*100)/100);
    setTotalWithDiscount(Math.round(discountCostSum*100)/100);
  },[productList])

  const copyClickHandler = (id) =>{
        const config = {
        headers: { 'Authorization': 'Bearer '+'5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp'}
      };
      axios.post('https://api.orders.e-interior.gr/api/product/'+id,{
        key: '5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp'},
        config
      )
      .then(function (response) {
        console.log(response.data);
        let productInfo = response.data;
        let productOptions = response.data.product_options;
        axios.post('https://api.orders.e-interior.gr/api/product/create',{
        key: '5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp',
        order_id: clientCookie.OrderId,
        product_type: productInfo.product_type,
        manufacturer: productInfo.manufacturer,
        code: productInfo.code,
        product_options: productOptions,
        status: "submitted",
        },
        config
      )
        .then(function (response) {
          setUpdatedProduct(response.data)

        })
        .catch(function (error) {
          console.log(error);
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const deleteClickHandler = (id) =>{
        const config = {
          headers: { 'Authorization': 'Bearer '+'5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp'}
        };

        axios.post('https://api.orders.e-interior.gr/api/product/update',{
          key: '5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp',
          order_id: cookies.OrderId,
          product_id: id,
          status: "deleted",
          },
          config
        )
        .then(function (response) {
          console.log(response.data);
          setUpdatedProduct(response.data)
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  return(
    <Fragment>
    {viewModal}
    {editModal}
    {modalShow && (selection === "Roller") && <Roller onClose={addCloseHandler} onSave={addSaveHandler}/>}
    {modalShow && (selection === "Fabric") && <Fabrics onClose={addCloseHandler} onSave={addSaveHandler}/>}
    {modalShow && (selection === "Carpet") && <XaliMoketaPlastiko onClose={addCloseHandler} onSave={addSaveHandler}/>}
    {modalShow && (selection === "Blinds") && <Persides onClose={addCloseHandler} onSave={addSaveHandler}/>}
    {modalShow && (selection === "Roman") && <RomanPseudoroman onClose={addCloseHandler} onSave={addSaveHandler}/>}
    {modalShow && (selection === "Curtain") && <CurtainRod onClose={addCloseHandler} onSave={addSaveHandler}/>}
    {modalShow && (selection === "Diadromos") && <Diadromos onClose={addCloseHandler} onSave={addSaveHandler}/>}
    {modalShow && (selection === "Custom") && <CustomProduct onClose={addCloseHandler} onSave={addSaveHandler}/>}

    <div className={`${classes.newProduct} ${modalShow ? classes.nonInteractable : ""}`}>
      <div className={classes.productLabel}>
      <span className={classes.dividerTitle}>Προϊόντα: </span>
      <span className={classes.divider}></span></div>
      <div className={classes.productListHeader}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      {productList.map(({id,order_id,product_type,code,product_options,status})=>{
        if (order_id==clientCookie.OrderId){
          let totalPrice =  Number(JSON.parse(product_options).totalPrice);
          let discountPrice = JSON.parse(product_options).discountPrice ? Number(JSON.parse(product_options).discountPrice):false;
          let totalPriceDiscount = JSON.parse(product_options).totalPriceDiscount ? Number(JSON.parse(product_options).totalPriceDiscount):false;
          let installPrice = JSON.parse(product_options).installPrice ? Number(JSON.parse(product_options).installPrice):0;
          let installTotal = JSON.parse(product_options).installPrice ? JSON.parse(product_options).totalPriceDiscount ? Math.round((installPrice+totalPriceDiscount)*100)/100:(JSON.parse(product_options).discountPrice ? Math.round((installPrice+discountPrice)*100)/100:Math.round((installPrice+totalPrice)*100)/100):'-';
          return (<Fragment>
          <div className={`${classes.productList} ${status==='deleted' && classes.deleted}`}>
          <span>Είδος: {product_type} {code}{JSON.parse(product_options).quantity&&" x "+JSON.parse(product_options).quantity} τμχ</span>
          <span>Τιμή: {totalPrice ? totalPrice+'€':'-'}</span>
          <span>Έκπτωση:{totalPriceDiscount ? (totalPriceDiscount+'€'):discountPrice ? (discountPrice+'€'):'-'}</span>
          <span>
            Ενέργειες: <span style={{marginRight:1 + 'em'}} onClick={()=>viewClickHandler(id,product_type)}>{view}</span>
            <span onClick={()=>deleteClickHandler(id)}>{trash}</span>
          </span>
          </div>
          <span className={classes.divider}></span>
          </Fragment>)
        }
        }
      )}
      <span className={classes.divider}></span>
      <p>Σύνολο: {totalProductCost}€</p>
      {discount&&<p>Σύνολο με έκπτωση: {totalWithDiscount}€</p>}

      <div className={classes.searchBlock}>
      <span>
        <label htmlFor="addNew">Προσθήκη νέου προϊόντος: </label>
        <select id="addNew" name="Προσθήκη" value={selection} onChange={selectChangeHandler}>
          <option value="0"> </option>
          <option value="Curtain">Κουρτινόξυλο</option>
          <option value="Blinds">Περσίδες</option>
          <option value="Roman">Ρόμαν-Ψευτορόμαν</option>
          <option value="Roller">Roller</option>
          <option value="Fabric">Ύφασμα</option>
          <option value="Carpet">Χαλί-Μοκέτα-Πλαστικό</option>
          <option value="Diadromos">Διάδρομος</option>
          <option value="Custom">Άλλο είδος</option>
        </select>
      </span>
      </div>

      <div className={`${classes.ButtonSet}`}>
      <span></span>
         <Link to="/">
       <span className={classes.cancelButton}><Button>Ακύρωση</Button></span>
       </Link>
      <span></span>
      {productList.find(obj => obj.order_id==clientCookie.OrderId) && 
      <Fragment>
      <Link to="../2" state={discount?{ initPrice: totalProductCost, totalCost: totalWithDiscount, installPrice: totalInstallCost}:{ initPrice: totalProductCost, totalCost:totalProductCost, installPrice: totalInstallCost}}>
      <span className={classes.nextButton}><Button>Επόμενο</Button></span>
      </Link>
      <span></span>
      </Fragment>}
      </div>
    </div>
    </Fragment>
  )
}

export default NewOrderProductEmployee;
