import React,{useState,useEffect,Fragment} from 'react';
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";
import {useCookies} from 'react-cookie';
import Modal from '../UI/Modal';
import Input from '../UI/Input';
import Button from '../UI/Button';
import classes from '../NewOrder/NewOrderProducts/Diadromos.module.css';
import axios from 'axios';
import { Typeahead } from 'react-bootstrap-typeahead'; 

const DiadromosEdit = (props) =>{
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const [companies, setCompanies]=useState('');
  const [sku,setSku]=useState('');
  const [companySelection, setCompanySelection] = useState([]);
  const [companyDropdown, setCompanyDropdown] = useState('');
  const [quantity, setQuantity] = useState('1');
  const [meterPrice, setMeterPrice]=useState('');
  const [width, setWidth]=useState('');
  const [length, setLength]=useState('');
  const [installation, setInstallation]=useState('oxi');
  const [installPrice, setInstallPrice]=useState(0);
  const [manualInstallPrice, setManualInstallPrice] = useState(0);
  const [totalPrice, setTotalPrice]=useState('');
  const [discount,setDiscount]=useState('');
  const [discountPrice, setDiscountPrice]=useState('');
  const [totalPriceDiscount, setTotalPriceDiscount]=useState('');
  const [notes, setNotes]=useState('');
  const [errorMessage,setErrorMessage]=useState('');
  const [productInfo, setProductInfo] = useState('');
  const [productOptions, setProductOptions] = useState('');
  const [options,setOptions]=useState({id:'',label:''});

  const [isLoaded, setIsLoaded] = useState(false);
  
  useEffect(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    console.log('proped id is :' +props.id);
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
    };
    axios.post('https://api.orders.e-interior.gr/api/general/init',{
      key: cookies.Token},
      config
    )
    .then(function (response) {
      setCompanies(response.data[0].companies);

    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
      console.log(error);
    });
    axios.post('https://api.orders.e-interior.gr/api/product/'+props.id,{
      key: cookies.Token},
      config
    )
    .then(function (response) {
      console.log(response.data);
      setProductInfo(response.data)
      setProductOptions(JSON.parse(response.data.product_options))
    })
    .catch(function (error) {
      console.log(error);
    });
  },[])

  useEffect(()=>{
    setCompanySelection((productInfo.manufacturer && productInfo.manufacturer !=0)  ? [productInfo.manufacturer]:[]);
    setSku(productInfo.code);
    setQuantity(productOptions.quantity ? productOptions.quantity:'1');
    setMeterPrice(productOptions.meterPrice ? productOptions.meterPrice:'');
    setWidth(productOptions.width ? productOptions.width:'');
    setLength(productOptions.length ? productOptions.length:'');
    setInstallation(productOptions.installation ? productOptions.installation:'oxi');
    setInstallPrice(productOptions.installPrice ? productOptions.installPrice:0);
    setManualInstallPrice(productOptions.installPrice ? productOptions.installPrice/productOptions.quantity:0)
    setNotes(productOptions.notes ? productOptions.notes:'');
    setDiscount(productOptions.discount ? productOptions.discount:false);
    setDiscountPrice(productOptions.discountPrice ? productOptions.discountPrice:'');
    setTotalPriceDiscount(productOptions.totalPriceDiscount ? productOptions.totalPriceDiscount:'');
    setTotalPrice(productOptions.totalPrice ? productOptions.totalPrice:'');
  },[productOptions])
  useEffect(()=>{
    if (installation==="oxi"){
      setInstallPrice(0);
      setManualInstallPrice(0);
    }
  }, [installation])
  useEffect(()=>{
    setTotalPrice(Math.round((parseFloat(length)*parseFloat(meterPrice)+(parseFloat(installPrice)))*quantity*100)/100);
  },[length,meterPrice,installation, installPrice, quantity])


  const handleFocus = (event) => {
      const { name, value } = event.target;
      if (value){
      const inputValue = parseFloat(value);


      if (name === 'width') {
        setWidth(inputValue);
      } else if (name === 'length') {
        setLength(inputValue);
      }
    }
    }
  const handleBlur = (event) => {
    const { name, value } = event.target;
    if (value){
    const inputValue = parseFloat(value);
    const formattedValue = inputValue.toFixed(2) + 'm';

    if (name === 'width') {
      setWidth(formattedValue);
    } else if (name === 'length') {
      setLength(formattedValue);
    }
  }
  }
  
  const companySelectionHandler = (event) =>{
    setCompanySelection(event.target.value);
  }

  const quantityChangeHandler = (event)=>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    value = value.trim();
    if (value === '') {
      setQuantity(''); // Set quantity as an empty string if input is empty
    } else {
      value = parseInt(value);
      if (isNaN(value)) {
        setQuantity(''); // Set quantity as an empty string if value is NaN
      } else {
        setQuantity(value);
      }
    }
  }
  const meterPriceChangeHandler = (event)=>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setMeterPrice(value);
    }
  }
  const widthChangeHandler = (event) =>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setWidth(value);
    }
  }
  const lengthChangeHandler = (event) =>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setLength(value);
    }
  }
  const discountChangeHandler = (event) =>{
    if (discount){
      setDiscount(false);
    }else{
      setDiscount(true);
    }
  }
  const discountPriceChangeHandler = (event) =>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setDiscountPrice(value);
    }
  }
  const installationHandler = (event) =>{
    setInstallation(event.target.value);
  }
  const installationPriceChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setInstallPrice(value);
      setManualInstallPrice(value);
    }
  }
  const notesChangeHandler = (event) =>{
    setNotes(event.target.value);
  }
  const skuChangeHandler = (event) =>{
    setSku(event.target.value);
  }

    const handleSaveClick = (e) =>{
      if ( companySelection!=="0"&&totalPrice!='0'){
        const config = {
          headers: { 'Authorization': 'Bearer '+cookies.Token}
        };
        const productOptions = JSON.stringify({
          quantity:quantity,
          meterPrice:meterPrice,
          width:width,
          length:length,
          installation:installation,
          installPrice:installPrice,
          totalPrice: totalPrice,
          discount:discount,
          discountPrice: discount ? discountPrice:'',
          totalPriceDiscount: discount ? totalPriceDiscount:'',
          notes:notes
        })

        axios.post('https://api.orders.e-interior.gr/api/product/update',{
        key: cookies.Token,
        product_id:props.id,
        product_type: 'Διάδρομος',
        manufacturer: companySelection[0].label,
        code: sku,
        product_options: productOptions,
        },
        config
      )
        .then(function (response) {
          console.log(response.data);
          props.onSave({data:response.data})

        })
        .catch(function (error) {
          if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
          console.log(error);
          e.preventDefault();
        });
      }else{
        setErrorMessage('Missing required fields');
      }
    } 

    useEffect(()=>{
      if (companies){
        setOptions(companies.map((key) => ({
                id: key,
                label: `${key}`,
              })))}
    }, [companies])

    useEffect(()=>{
      setTotalPriceDiscount(Number(discountPrice)*Number(quantity));
    },[discountPrice,quantity])

  useEffect (()=>{
    if (manualInstallPrice>0){
      setInstallPrice(manualInstallPrice*quantity);
    }
  },[quantity])
    
  return(
    <Modal className={classes.singleModal}>
      <h2 className={classes.title}>Παραγγελία Διάδρομος</h2>
      {errorMessage}
      
      <div className={classes.textarea}>
      <label htmlFor="sku">Κωδικός/Περιγραφή: </label>
      <textarea id="sku" rows="2" value={sku} onChange={skuChangeHandler}></textarea>
      </div>
      <div className={classes.searchBlock}>
        <span>
          <label htmlFor="type">Εταιρεία: </label>
            {options && 
            <Typeahead
                className={classes.typeaheadSelect}
                id="basic-typeahead-single"
                labelKey="label"
                onChange={setCompanySelection}
                options={options}
                placeholder=""
                defaultSelected={companySelection}
                selected={companySelection}
            />}
        </span>
      </div>
      
      <Input
      onChange={meterPriceChangeHandler}
      label="Τιμή μέτρου:"
      isPrice="true"
      input={{
        id: 1,
        type: 'text',
        value: meterPrice
      }} />
      <Input
      onChange={widthChangeHandler}
      onBlur={handleBlur}
      onFocus={handleFocus}
      label="Πλάτος (m):"
      input={{
        id: 1,
        name: 'width',
        type: 'text',
        value: width
      }} />
      <Input
      onChange={lengthChangeHandler}
      onBlur={handleBlur}
      onFocus={handleFocus}
      label="Μήκος (m):"
      input={{
        id: 1,
        name: 'length',
        type: 'text',
        value: length
      }} />
      <div className={classes.searchBlock}>
        <span>
          <label htmlFor="installation">Τοποθέτηση: </label>
          <select id="installation" name="installation" onChange={installationHandler} value={installation}>
            <option value="0"> </option>
            <option value="nai">Ναι</option>
            <option value="oxi">Όχι</option>
          </select>
        </span>
        { installation ==="nai" &&
        <p style={{marginTop:10+'px'}}>
        <Input
        onChange={installationPriceChangeHandler}
        label="Κόστος:"
        input={{
          id: 1,
          type: 'text',
          value: installPrice
        }} /></p>}
      </div>
      {totalPrice>0&&<p>Τιμή: {totalPrice}€</p>}
      <div>
      <Button className={classes.discountBtn} onClick={discountChangeHandler}>Έκπτωση;</Button>
      {!discount && <p></p>}
      {discount && 
      <Fragment>
      <Input
      onChange={discountPriceChangeHandler}
      label="Τιμή με έκπτωση:"
      isPrice="true"
      input={{
        id: 1,
        type: 'text',
        value: discountPrice
      }} />
      <p>Σύνολο με έκπτωση: {totalPriceDiscount}€</p>
      </Fragment>
      }
      </div>
      <div className={classes.textarea}>
      <label htmlFor="sku">Σημειώσεις: </label>
      <textarea id="sku" rows="2" value={notes} onChange={notesChangeHandler}></textarea>
      </div>
      <Input
      onChange={quantityChangeHandler}
      label="Ποσότητα:"
      input={{
        id: 1,
        type: 'text',
        value: quantity
      }} />
      <div className={classes.actions}>
        <button className={classes[`button--alt`]} onClick={props.onClose}>Πίσω</button>
        <button onClick={handleSaveClick} className={classes[`button--alt`]}>Αποθήκευση</button>
      </div>
    </Modal>
  )
}
export default DiadromosEdit;