import React,{useState,useEffect, Fragment} from 'react';
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";
import {useCookies} from 'react-cookie';
import Modal from '../../UI/Modal';
import Input from '../../UI/Input';
import Button from '../../UI/Button';
import OrderContext from '../../../store/order-context'
import classes from './Views.module.css';
import axios from 'axios';

const PersidesView = (props) =>{
  //states
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const [isLoaded, setIsLoaded] = useState(false);
  const [productInfo, setProductInfo] = useState(null);
  const [productOptions, setProductOptions] = useState(null);

  useEffect(() => {
    setIsLoaded(true);
}, []);

  useEffect(() => {
    console.log('proped id is :' +props.id);
    const config = {
      headers: { Authorization: "Bearer 5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp"}
    };
    axios.post('https://api.orders.e-interior.gr/api/product/'+props.id,{
      key: cookies.Token},
      config
    )
    .then(function (response) {
      console.log(response.data);
      setProductInfo(response.data)
      setProductOptions(JSON.parse(response.data.product_options))
    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
        removeCookies('Name', { path: '/' });
        removeCookies('Token', { path: '/' });
        navigate("/user-login");
      }
      console.log(error);
    });
  },[isLoaded])

  return(
    <Modal className={classes.modal} onClose={props.onClose}>
      {productInfo && 
      <Fragment>
      <h2 className={classes.title}>Παραγγελία {productInfo.product_type}</h2>
      <p>Είδος: {productInfo.product_type}</p>
      <p>Εταιρεία: {productInfo.manufacturer}</p>
      <p>Κωδικός: {productInfo.code}</p>
      
      </Fragment>
      }
      {productOptions &&
        <Fragment>
          <p>Ποσότητα: {productOptions.quantity}</p>
          <p>Τιμή μέτρου: {productOptions.ftSquarePrice}</p>
          <p>Θέση χειριστηρίων: {productOptions.controllerPlacementSelection}</p>
          {productInfo.product_type.includes("Κάθετες")&&<Fragment>
          <p>Μηχανισμός: {productOptions.mechanismSelection}</p>
          <p>Πλάτος Φύλλου: {parseFloat(productOptions.widthFyllou)}m</p>
          <p>Ποσότητα Φύλλων: {productOptions.quantityFyllou}</p>
          
          <p>Πως θα μαζεύουν τα φύλλα: {productOptions.directionSelection}</p>
          </Fragment>}
          {productOptions.supportSelection!=="0"&&<p>Στήριξη σε: {productOptions.supportSelection}</p>}
          <p>Πλάτος Παραθύρου: {parseFloat(productOptions.windowWidth)}m</p>
          <p>Ύψος Παραθύρου: {parseFloat(productOptions.windowHeight)}m</p>
          <p>Μέρος τοποθέτησης: {productOptions.installPosition}</p>
          <p>Ανοιγόμενο ή Συρόμενο: {productOptions.wayOfOpening}</p>
          <p>Εμπόδιο: {productOptions.obstacle==="Yes"?"Ναι":"Όχι"}</p>
          {productOptions.obstacle==="Yes"&&<p>Απόσταση από εμπόδιο: {productOptions.distance}cm</p>}
          {productOptions.aboveWindow&&<p>Πόσο πάνω από το παράθυρο θα τοποθετηθούν οι περσίδες: {productOptions.aboveWindow}cm</p>}
          <p>Πλάτος: {parseFloat(productOptions.rollerWidth)}m</p>
          <p>Ύψος: {parseFloat(productOptions.rollerHeight)}m</p>
          <p>Τιμή: {productOptions.totalPrice}€</p>
          <p>Τοποθέτηση: {productOptions.installation==="nai"?"Ναι":"Όχι"}</p>
          <p>Κόστος τοποθέτησης: {productOptions.installPrice}€</p>
          <p>Σημειώσεις: {productOptions.freeText}</p>
          <p>Πελάτης: {props.client_name}</p>
          <button className={classes.close} onClick={props.onClose}>Πίσω</button>
        </Fragment>
      }
    </Modal>
  )
}

export default PersidesView;
