import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import App from './App';
import MainButtons from './components/MainButtons/MainButtons';
import Placements from './components/Placements/Placements';
import ToOrder from './components/ToOrder/ToOrder';
import AtTailor from './components/AtTailor/AtTailor';
import Deficiencies from './components/Deficiencies/Deficiencies';
import ForWarehouse from './components/ForWarehouse/ForWarehouse';
import ProductList from './components/ProductList/ProductList';
import UserLogin from './components/UserLogin/UserLogin';
import NewOrder from './components/NewOrder/NewOrder';
import NewOrderEmpty from './components/NewOrder/NewOrderEmpty'
import NewOrderClient from './components/NewOrder/NewOrderClient';
import NewOrderProduct from './components/NewOrder/NewOrderProduct';
import NewOrderTasks from './components/NewOrder/NewOrderTasks';
import NewOrderEmployee from './components/NewOrderEmployee/NewOrderEmployee';
import NewOrderEmptyEmployee from './components/NewOrderEmployee/NewOrderEmptyEmployee'
import NewOrderProductEmployee from './components/NewOrderEmployee/NewOrderProductEmployee';
import NewOrderTasksEmployee from './components/NewOrderEmployee/NewOrderTasksEmployee';
import NewOrderLoadingEmployee from './components/NewOrderEmployee/NewOrderLoadingEmployee';
import OrdersInProgress from './components/OrdersInProgress/OrdersInProgress';
import OrderHistory from './components/OrderHistory/OrderHistory';
import OrdersNotSubmitted from './components/OrdersNotSubmitted/OrdersNotSubmitted';
import AppHistory from './components/AppHistory/AppHistory';
import Xmls from './components/Xmls/Xmls';
import Nima from './components/Xmls/Nima';
import Ezzo from './components/Xmls/Ezzo';
import Isexan from './components/Xmls/Isexan';
import Madi from './components/Xmls/Madi';
import RoyalCarpet from './components/Xmls/RoyalCarpet';
import Soulworks from './components/Xmls/Soulworks';
import Teoran from './components/Xmls/Teoran';
import Clients from './components/Clients/Clients';
import Turnover from './components/Turnover/Turnover';
import reportWebVitals from './reportWebVitals';


ReactDOM.render(
  <BrowserRouter>
  <Routes>
  <Route path="/" element={<App/>}>
  <Route path="/" element={<MainButtons/>} />
  <Route path="installations" element={<Placements/>} />
  <Route path="to-order" element={<ToOrder/>} />
  <Route path="deficiencies" element={<Deficiencies/>} />
  <Route path="at-tailor" element={<AtTailor/>} />
  <Route path="for-warehouse" element={<ForWarehouse/>} />
  <Route path="product-list" element={<ProductList/>}/>
  <Route path="user-login" element={<UserLogin/>} />
  <Route path="new-order" element={<NewOrder/>}>
    <Route path="0" element={<NewOrderEmpty/>}/>
    <Route path="1" element={<NewOrderProduct/>}/>
    <Route path="2" element={<NewOrderTasks/>}/>
    <Route path="3" element={<NewOrderClient/>}/>
  </Route>
  <Route path="new-order-employee" element={<NewOrderEmployee/>}>
    <Route path="0" element={<NewOrderEmptyEmployee/>}/>
    <Route path="1" element={<NewOrderProductEmployee/>}/>
    <Route path="2" element={<NewOrderTasksEmployee/>}/>
    <Route path="3" element={<NewOrderLoadingEmployee/>}/>
  </Route>
  <Route path="in-progress" element={<OrdersInProgress/>} />
  <Route path="not-submitted" element={<OrdersNotSubmitted/>}/>
  <Route path="app-history" element={<AppHistory/>} />
  <Route path="xmls" element={<Xmls/>} />
  <Route path="order-history" element={<OrderHistory/>} />
  <Route path="clients" element={<Clients/>} />
  <Route path="turnover" element={<Turnover/>} />
  <Route path="nima" element={<Nima/>} />
  <Route path="ezzo" element={<Ezzo/>} />
  <Route path="isexan" element={<Isexan/>} />
  <Route path="madi" element={<Madi/>} />
  <Route path="royal-carpet" element={<RoyalCarpet/>} />
  <Route path="soulworks" element={<Soulworks/>} />
  <Route path="teoran" element={<Teoran/>} />
  <Route
    path="*"
    element={
      <main style={{ padding: "1rem" }}>
        <p>There's nothing here!</p>
      </main>
    }
  />
</Route>
</Routes>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
