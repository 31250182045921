import React,{Fragment,useState,useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faPencilAlt, faTrash, faStar, faTriangleExclamation} from '@fortawesome/free-solid-svg-icons'
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";
import {useCookies} from 'react-cookie';
import classes from './ClientsImport.module.css'
import ClientDelete from './ClientDelete'
import axios from 'axios';

const view = <FontAwesomeIcon icon={faEye} />
const edit = <FontAwesomeIcon icon={faPencilAlt} />
const trash = <FontAwesomeIcon icon={faTrash} />
const star = <FontAwesomeIcon icon={faStar} />;
const warning = <FontAwesomeIcon icon={faTriangleExclamation} />;

const ClientsImport = (props) =>{
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const [isLoaded, setIsLoaded] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);


  useEffect(() => {
      setIsLoaded(true);
  }, []);

  const handleViewClick = () =>{
    props.onView({id:props.id})
  }
  const handleEditClick = () =>{
    props.onEdit({id:props.id})
  }
  const handleVipClick = () =>{
    props.onVip({id:props.id, vip:props.vip})
  }
  const handleWarningClick = () =>{
    props.onWarning({id:props.id, warning:props.warning})
  }

  const handleDeleteClick = (e) =>{
    setDeleteModal(true);
  }

  const handleDeleteYes = (e) =>{
    
    const config = {
        headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
    axios.post('https://api.orders.e-interior.gr/api/client/update',
        {client_id: props.id,
        deleted:1},
        config)
      .then(function (response) {
        console.log(response);
        props.onDelete(response)
        setDeleteModal(false);
      })
      .catch(function (error) {
        if (error.response.data.message==="Unauthenticated."){
        removeCookies('Name', { path: '/' });
        removeCookies('Token', { path: '/' });
        navigate("/user-login");
      }
        console.log(error);
      });
  }
  const handleDeleteNo = (e) =>{
    setDeleteModal(false);
  }
  return(
    <Fragment>
    {deleteModal && <ClientDelete id={props.id} yesClick={handleDeleteYes} noClick={handleDeleteNo}/>}
      <tr className={classes.singleClient}>
        <td onClick={handleViewClick}>{props.id}</td>
        <td onClick={handleViewClick}>{props.full_name}</td>
        <td onClick={handleViewClick}>{props.phone}</td>
        <td onClick={handleViewClick}>{props.totalOrders}</td>
        <td onClick={handleViewClick}>{props.totalIncome}€</td>
        <td onClick={handleVipClick} style={{ color: props.vip === 1 ? 'gold' : 'inherit' }}>{star}</td>
        <td onClick={handleWarningClick} style={{ color: props.warning === 1 ? 'red' : 'inherit' }}>{warning}</td>
        <td onClick={handleEditClick}>{edit}</td>
        <td onClick={handleDeleteClick}>{trash}</td>
      </tr>
    </Fragment>)
}

export default ClientsImport;