import React,{useState,useEffect,Fragment} from 'react';
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";
import {useCookies} from 'react-cookie';
import Input from '../../UI/Input';
import Button from '../../UI/Button';
import classes from './CustomProduct.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { Typeahead } from 'react-bootstrap-typeahead'; 

const CustomProduct = (props) =>{
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const [companies, setCompanies]=useState('');
  const [companySelection, setCompanySelection] = useState([]);
  const [companyDropdown, setCompanyDropdown] = useState('');
  const [productName, setProductName]=useState('');
  const [price, setPrice]=useState(0);
  const [quantity, setQuantity]=useState('1');
  const [totalPrice, setTotalPrice]=useState('');
  const [discount,setDiscount]=useState('');
  const [discountPrice, setDiscountPrice]=useState('');
  const [totalPriceDiscount, setTotalPriceDiscount]=useState('');
  const [notes, setNotes]=useState('');
  const [errorMessage,setErrorMessage]=useState('');
  const [options,setOptions]=useState({id:'',label:''});

  const [isLoaded, setIsLoaded] = useState(false);

  const [buttonDisabled, setButtonDisabled] = useState(false);
  
  useEffect(() => {
    setIsLoaded(true);
}, []);
  useEffect(() => {
    const config = {
      headers: { 'Authorization': 'Bearer '+'5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp'}
    };
    axios.post('https://api.orders.e-interior.gr/api/general/init',{
      key: '5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp'},
      config
    )
    .then(function (response) {
      setCompanies(response.data[0].companies);

    })
    .catch(function (error) {
      console.log(error);
    });

  },[isLoaded])

  useEffect(()=>{
    setTotalPrice(Number(price)*Number(quantity));
  },[price,quantity])

  const companySelectionHandler = (event) =>{
    setCompanySelection(event.target.value);
  }
  const productNameChangeHandler = (event)=>{
  	setProductName(event.target.value);
  }

  const priceChangeHandler = (event)=>{
  	let value = event.target.value.toString();
    value = value.replace(/,/g,".");
  	
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setPrice(value);
    }

  }
  const quantityChangeHandler = (event)=>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    value = value.trim();
    if (value === '') {
      setQuantity(''); // Set quantity as an empty string if input is empty
    } else {
      value = parseInt(value);
      if (isNaN(value)) {
        setQuantity(''); // Set quantity as an empty string if value is NaN
      } else {
        setQuantity(value);
      }
    }
  }
  const discountChangeHandler = (event) =>{
    if (discount){
      setDiscount(false);
    }else{
      setDiscount(true);
    }
  }
  const discountPriceChangeHandler = (event) =>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setDiscountPrice(value);
    }
  }
  const notesChangeHandler = (event) =>{
  	setNotes(event.target.value);
  }

    const handleSaveClick = (e) =>{
	    if ( price!='0'&& !buttonDisabled){
        setButtonDisabled(true);
        console.log(price);

	      const config = {
	        headers: { 'Authorization': 'Bearer '+'5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp'}
	      };
	      const productOptions = JSON.stringify({
          price:price,
          quantity:quantity,
	        totalPrice: totalPrice,
	        discount:discount,
	        discountPrice: discount ? discountPrice:'',
          totalPriceDiscount: discount ? totalPriceDiscount:'',
	        notes:notes
	      })

	      axios.post('https://api.orders.e-interior.gr/api/product/create',{
	        key: '5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp',
	        order_id: props.OrderId?props.OrderId:cookies.OrderId,
	        product_type: 'Άλλο είδος',
	        code: productName,
	        manufacturer: companySelection[0]? companySelection[0].label : 0,
	        product_options: productOptions,
	        status: "submitted",
	        ordered_at: null
	        },
	        config
	      )
	      .then(function (response) {
	        console.log(response.data);
	        props.onSave({data:response.data})
          setButtonDisabled(false);
	      })
	      .catch(function (error) {
          setButtonDisabled(false);
	        console.log(error);
	        e.preventDefault();
	      });
	  	}else{
      	setErrorMessage('Λείπουν υποχρεωτικά πεδία');
    	}
      
  	}	

    useEffect(()=>{
      if (companies){
        setOptions(companies.map((key) => ({
                id: key,
                label: `${key}`,
              })))}
    }, [companies])

    useEffect(()=>{
      setTotalPriceDiscount(Number(discountPrice)*Number(quantity));
    },[discountPrice,quantity])

  return(
  	<div className={classes.newProduct}>
      <h2 className={classes.title}>Παραγγελία Άλλου Είδους</h2>
      {errorMessage}
      
      <Input
      onChange={productNameChangeHandler}
      label="Όνομα Προϊόντος:"
      vert="true"
      input={{
        id: 1,
        type: 'text',
        value: productName
      }} />
      <div className={classes.searchBlock}>
        <span>
          <label htmlFor="type">Εταιρεία: </label>
            {options.length>0 ? 
            <Typeahead
                className={classes.typeaheadSelect}
                id="basic-typeahead-single"
                labelKey="label"
                onChange={setCompanySelection}
                options={options}
                placeholder=""
                defaultSelected={companySelection}
                selected={companySelection}
            />:
            <FontAwesomeIcon className={classes.loadingIcon} icon={faCircleNotch} spin />
          }
        </span>
      </div>
      <Input
      onChange={priceChangeHandler}
      label="Τιμή:"
      vert="true"
      isPrice="true"
      input={{
        id: 1,
        type: 'text',
        value: price
      }} />
      
      <p>Τιμή Σύνολο: {totalPrice}€</p>
      <div>
      <Button className={classes.discountBtn} onClick={discountChangeHandler}>Έκπτωση;</Button>
      {!discount && <p></p>}
      {discount && 
      <Fragment>
      <Input
      onChange={discountPriceChangeHandler}
      label="Τιμή με έκπτωση:"
      vert="true"
      isPrice="true"
      input={{
        id: 1,
        type: 'text',
        value: discountPrice
      }} />
      <p>Σύνολο με έκπτωση: {totalPriceDiscount}€</p>
      </Fragment>}
      </div>
      <div className={classes.textarea}>
      <label htmlFor="sku">Σημειώσεις: </label>
      <textarea id="sku" rows="2" value={notes} onChange={notesChangeHandler}></textarea>
      </div>
      <Input
      onChange={quantityChangeHandler}
      label="Ποσότητα:"
      vert="true"
      input={{
        id: 1,
        type: 'text',
        value: quantity
      }} />
      <div className={classes.actions}>
        <Button className={classes[`button--alt`]} onClick={props.onClose}>Πίσω</Button>
        <Button className={`${classes[`button--alt`]} ${buttonDisabled? classes.buttonLoad:''}`} disabled={buttonDisabled} onClick={handleSaveClick} >{buttonDisabled?<FontAwesomeIcon icon={faCircleNotch} spin />:'Αποθήκευση'}</Button>
      </div>
    </div>
  )
}
export default CustomProduct;