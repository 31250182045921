import React,{useState,useEffect,Fragment} from 'react';
import {useCookies} from 'react-cookie';
import { useNavigate } from "react-router-dom";
import Modal from '../UI/Modal';
import Button from '../UI/Button';
import Input from '../UI/Input'
import classes from './EditTasks.module.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';


const EditTasks = (props) =>{
	const [cookies, setCookies, removeCookies] = useCookies(['user']);
  	const navigate = useNavigate();
	const [nowLoading, setNowLoading] = useState(false);
	const [paradosi,setParadosi] = useState(props.orderExtras.paradosi??"");
	const [metrisi,setMetrisi] = useState(props.orderExtras.metrisi??"");
	const [rafis,setRafis] = useState(props.orderExtras.rafis??"");
	const [sideroma,setSideroma] = useState(props.orderExtras.sideroma??"");
	const [topothetisi,setTopothetisi] = useState(props.orderExtras.topothetisi??"");
	
	const paradosiChangeHandler = (event)=>{
	  let value = event.target.value.toString();
	  value = value.replace(/,/g,".");
	  setParadosi(value);
	}
	const metrisiChangeHandler = (event)=>{
	  let value = event.target.value.toString();
	  value = value.replace(/,/g,".");
	  setMetrisi(value);
	}
	const topothetisiChangeHandler = (event)=>{
	  let value = event.target.value.toString();
	  value = value.replace(/,/g,".");
	  setTopothetisi(value);
	}
	const rafisChangeHandler = (event)=>{
	  let value = event.target.value.toString();
	  value = value.replace(/,/g,".");
	  setRafis(value);
	}
	const sideromaChangeHandler = (event)=>{
	  let value = event.target.value.toString();
	  value = value.replace(/,/g,".");
	  setSideroma(value);
	}

	const handleSaveClick = (e) =>{
      const config = {
        headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
      let currDate = new Date();
      const extras = JSON.stringify({
        paradosi: paradosi,
        metrisi: metrisi,
        topothetisi: topothetisi,
        rafis: rafis,
        sideroma: sideroma,
      })
      axios.post('https://api.orders.e-interior.gr/api/order/update',{
            key: cookies.Token,
            order_id: props.id,
            extras:extras,
            },
            config
          )
          .then(function (response) {
            console.log(response.data);
            props.onExtrasUpdate(response.data.order.extras); 
          })
          .catch(function (error) {
            if (error.response.data.message==="Unauthenticated."){
                removeCookies('Name', { path: '/' });
                removeCookies('Token', { path: '/' });
                navigate("/user-login");
              }
            console.log(error);
          });
  }
	return(
		<Fragment>
		{!nowLoading?
		<Modal className={classes.singleModal}>
			<button className={classes.close} onClick={props.onClose}>Πίσω</button>
			<div className={classes.serviceCost}>
		      <span className={classes.dividerTitle}><strong>Επιπλέον Υπηρεσίες/Κόστος: </strong></span>
		      <span className={classes.divider}></span>
		    </div>

		    <div className={classes.serviceForm}>
		    <p>
		      <Input onChange={paradosiChangeHandler} 
		      label="Κατ'οίκον παράδοση:"
		      isPrice="true"
		      input={{
		        id: 1,
		        type: 'text',
		        value: paradosi
		      }} />
		      </p>
		      <p>
		      <Input onChange={metrisiChangeHandler}
		      label="Κατ'οίκον μέτρηση:"
		      isPrice="true"
		      input={{
		        id: 2,
		        type: 'text',
		        value: metrisi
		      }} />
		      </p>
		      <p>
		      <Input onChange={topothetisiChangeHandler}
		      label="Κόστος Τοποθέτησης/Εργασίας:"
		      isPrice="true"
		      input={{
		        id: 3,
		        type: 'text',
		        value: topothetisi
		      }} />
		      </p>
		      <p>
		      <Input onChange={rafisChangeHandler}
		      label="Κόστος ραφής:"
		      isPrice="true"
		      input={{
		        id: 4,
		        type: 'text',
		        value: rafis
		      }} />
		      </p>
		      <p>
		      <Input onChange={sideromaChangeHandler}
		      label="Σιδέρωμα Κουρτίνας (2,50€/μ):"
		      isPrice="true"
		      input={{
		        id: 5,
		        type: 'text',
		        value: sideroma
		      }} />
		      </p>
		    </div>
		    <button className={classes.save} onClick={handleSaveClick}>Αποθήκευση</button>
		</Modal>:
		<Modal className={`${classes.singleModal} ${classes.loadingModal}`}>
			<FontAwesomeIcon className={classes.loadingIcon} icon={faCircleNotch} spin />
		</Modal>
		}
		</Fragment>
	)
}

export default EditTasks;