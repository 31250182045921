import React,{useState,useEffect,Fragment} from 'react';
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";
import {useCookies} from 'react-cookie';
import Modal from '../UI/Modal';
import Input from '../UI/Input';
import Button from '../UI/Button';
import OrderContext from '../../store/order-context'
import classes from '../NewOrder/NewOrderProducts/CurtainRod.module.css';
import axios from 'axios';
import { Typeahead } from 'react-bootstrap-typeahead'; 

const CurtainRodEdit = (props) =>{
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const [companies, setCompanies]=useState('');
  const [companySelection, setCompanySelection] = useState([]);
  const [companyDropdown, setCompanyDropdown] = useState('');
  const [sku, setSku]=useState('');
  const [typeSelection, setTypeSelection]=useState('0');
  const [quantity, setQuantity]=useState('1');
  const [rodPrice, setRodPrice]=useState('');
  const [windowWidth, setWindowWidth]=useState('');
  const [obstacle, setObstacle]=useState('No');
  const [obstacleDistance, setObstacleDistance]=useState('0');
  const [rodWidth, setRodWidth]=useState('');
  const [metopi, setMetopi]=useState('0');
  const [ringSelection, setRingSelection]=useState('No');
  const [rodSelection, setRodSelection]=useState('mono');
  const [totalPrice, setTotalPrice] = useState('');
  const [selectedPrice, setSelectedPrice] = useState('');
  const [priceHint, setPriceHint] = useState('');
  const [discount, setDiscount] = useState(false);
  const [discountPrice, setDiscountPrice] = useState('');
  const [totalPriceDiscount, setTotalPriceDiscount]=useState('');
  const [installRodSelection, setInstallRodSelection]=useState('oxi');
  const [installPrice, setInstallPrice] =useState('');
  const [manualInstallPrice, setManualInstallPrice] = useState('');
  const [notes, setNotes] = useState('');
  const [errorMessage, setErrorMessage] =useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [productInfo, setProductInfo] = useState('');
  const [productOptions, setProductOptions] = useState('');
  const [options,setOptions]=useState({id:'',label:''});
  const [firstUpdate, setFirstUpdate]=useState(0);

    useEffect(() => {
    setIsLoaded(true);
}, []);

  useEffect(() => {
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
    };
    axios.post('https://api.orders.e-interior.gr/api/general/init',{
      key: cookies.Token},
      config
    )
    .then(function (response) {
      setCompanies(response.data[0].companies);

    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
      console.log(error);
    });

    axios.post('https://api.orders.e-interior.gr/api/product/'+props.id,{
      key: cookies.Token},
      config
    )
    .then(function (response) {
      console.log(response.data);
      setProductInfo(response.data)
      setProductOptions(JSON.parse(response.data.product_options))
    })
    .catch(function (error) {

      console.log(error);
    });
  },[])

  useEffect(() => {
      let typeString = '';
      switch(productInfo.product_type){
        case 'Κουρτινόξυλο - Βέργα με κρίκους':

          typeString = 'hoops';

          break;
        case 'Κουρτινόξυλο - Σιδηρόδρομος':
          typeString = 'rail';
          break;
        case 'Κουρτινόξυλο - Μετώπη/Κορνίζα':
          typeString = 'frame';
          break;
        case 'Κουρτινόξυλο - Σιδηρόδρομος (fixed τιμές)':
          typeString = 'fixedRail';
          break;
      }
      let ringString = '';
      switch(productOptions.ringSelection){
        case 'Όχι':
          ringString = 'No';
          break;
        case 'Ναι':
          ringString = 'Yes';
          break;
      }
      let rodString = '';
      switch(productOptions.rodSelection){
        case 'Μονό':
          rodString = 'mono';
          break;
        case 'Διπλό με Οβάλ':
          rodString = 'doubleOval';
          break;
        case 'Διπλό με Βέργα':
          rodString = 'doubleRail';
          break;
      }
      let metopiString = '';
          switch(productOptions.metopi){
            case '0':
              metopiString = '0';
              break;
            case 'Μετώπη μόνο':
              metopiString = 'metopi';
              break;
            case 'Μετώπη με σιδ/μο':
              metopiString = 'metopiRail';
              break;
          }

    setCompanySelection((productInfo.manufacturer && productInfo.manufacturer !=0)  ? [productInfo.manufacturer]:[]);
    setSku(productInfo.code);
    setQuantity(productOptions.quantity ? productOptions.quantity:'1');
    setTypeSelection(typeString);
    setRodPrice(productOptions.rodPrice ? productOptions.rodPrice:'');
    setWindowWidth(productOptions.windowWidth ? productOptions.windowWidth:'');
    setObstacle(productOptions.obstacle ? productOptions.obstacle:'No');
    setObstacleDistance(productOptions.obstacleDistance ? productOptions.obstacleDistance:'0');
    setRodWidth(productOptions.rodWidth ? productOptions.rodWidth:'');
    setMetopi(metopiString);
    setRingSelection(ringString);
    setRodSelection(rodString);
    setTotalPrice(productOptions.totalPrice ? productOptions.totalPrice:'');
    setSelectedPrice((typeString==='hoops'||typeString==='fixedRail')&&productOptions.totalPrice?productOptions.totalPrice/productOptions.quantity:'');
    setDiscount(productOptions.discount ? productOptions.discount:false);
    setDiscountPrice(productOptions.discountPrice ? productOptions.discountPrice:'');
    setTotalPriceDiscount(productOptions.totalPriceDiscount ? productOptions.totalPriceDiscount:'');
    setInstallRodSelection(productOptions.installation ? productOptions.installation:'oxi');
    setInstallPrice(productOptions.installPrice ? productOptions.installPrice:'');
    setManualInstallPrice(productOptions.installPrice ? productOptions.installPrice/productOptions.quantity:'')
    setNotes(productOptions.notes ? productOptions.notes:'');
  },[productOptions])

  const handleFocus = (event) => {
      const { name, value } = event.target;
      if (value){
      const inputValue = parseFloat(value);


      if (name === 'windowWidth') {
        setWindowWidth(inputValue);
      } else if (name === 'rodWidth') {
        setRodWidth(inputValue);
      }
    }
    }

    const handleBlur = (event) => {
    const { name, value } = event.target;
    if (value){
    const inputValue = parseFloat(value);
    const formattedValue = inputValue.toFixed(3) + 'm';

    if (name === 'windowWidth') {
      setWindowWidth(formattedValue);
    } else if (name === 'rodWidth') {
      setRodWidth(formattedValue);
    }
  }
  }

  const companySelectionHandler = (event) =>{
    setCompanySelection(event.target.value);
  }
  const skuChangeHandler = (event)=>{
    setSku(event.target.value);
  }
  const typeSelectionHandler = (event) =>{
    setTypeSelection(event.target.value);
    setDiscount(false);
    setDiscountPrice('');
    setTotalPrice('');
    setSelectedPrice('');
  }
  const quantityChangeHandler = (event)=>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    value = value.trim();
    if (value === '') {
      setQuantity(''); // Set quantity as an empty string if input is empty
    } else {
      value = parseInt(value);
      if (isNaN(value)) {
        setQuantity(''); // Set quantity as an empty string if value is NaN
      } else {
        setQuantity(value);
      }
    }
  }
  const rodPriceHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setRodPrice(value);
    }
  }
  const windowWidthChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setWindowWidth(value);
    }
  }
  const obstacleSelectionHandler = (event) =>{

    setObstacle(event.target.value);
    setObstacleDistance('0');
  }
  const obstacleDistanceChangeHandler = (event) =>{
        // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setObstacleDistance(value);
    }
  }

  const rodWidthChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setRodWidth(value);
    }
  }
  const metopiSelectionHandler = (event) =>{
    setMetopi(event.target.value);
  }
  const ringSelectionHandler = (event) =>{
    setRingSelection(event.target.value);
  }
  const rodSelectionHandler = (event) =>{
    setRodSelection(event.target.value);
  }

  const totalPriceChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setSelectedPrice(value);
      setTotalPrice(value);
    }
  }

  useEffect (()=>{
    if (typeSelection === 'rail'&& ringSelection === 'No'){
      setTotalPrice(Math.round((parseFloat(rodWidth))*parseFloat(rodPrice)*quantity*100)/100);
    }else if (typeSelection === 'rail'&& ringSelection === 'Yes'){
      setTotalPrice(Math.round(((parseFloat(rodWidth))*parseFloat(rodPrice)+5)*quantity*100)/100);
    }else if(typeSelection === "frame"){
      setTotalPrice(Math.round((parseFloat(rodWidth) + 0.31)*parseFloat(rodPrice)*quantity*100)/100);
    }
  },[rodWidth,rodPrice,typeSelection,quantity])

useEffect (()=>{
  if (typeSelection === 'fixedRail' || typeSelection === 'hoops'){
      setTotalPrice(selectedPrice*quantity);
    }
  },[quantity])

  const discountChangeHandler = (event) =>{
    if (discount){
      setDiscount(false);
    }else{
      setDiscount(true);
    }
  }
  const discountPriceChangeHandler = (event) =>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setDiscountPrice(value);
    }
  }

  const installRodSelectionHandler = (event) =>{
    setInstallRodSelection(event.target.value);
  }
  const installPriceChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setInstallPrice(value);
      setManualInstallPrice(value);
    }
  }
  const notesChangeHandler = (event) =>{
    setNotes(event.target.value);
  }
  const handleSaveClick = () =>{
    if ( companySelection!='0'&&sku.length>0&&typeSelection!='0'&&windowWidth.length>0){
      const config = {
        headers: { 'Authorization': 'Bearer '+cookies.Token}
      };

      let typeString ='';
      switch(typeSelection){
      case 'hoops':
        typeString="Κουρτινόξυλο - Βέργα με κρίκους"
        break;
      case 'rail':
        typeString="Κουρτινόξυλο - Σιδηρόδρομος"
        break;
      case 'frame':
        typeString="Κουρτινόξυλο - Μετώπη/Κορνίζα"
        break;
      case 'fixedRail':
        typeString="Κουρτινόξυλο - Σιδηρόδρομος (fixed τιμές)"
        break;
      }
      let ringString=''
      switch(ringSelection){
        case "No":
          ringString='Όχι'
          break;
        case "Yes":
          ringString='Ναι'
          break;
      }
      let rodString=''
      switch(rodSelection){
        case "mono":
          rodString='Μονό'
          break;
        case "doubleOval":
          rodString='Διπλό με Οβάλ'
          break;
        case "doubleRail":
          rodString='Διπλό με Βέργα'
          break;
      }
      let metopiString=''
      switch(metopi){
        case "0":
          metopiString='0'
          break;
        case "metopi":
          metopiString='Μετώπη μόνο'
          break;
        case "metopiRail":
          metopiString='Μετώπη με σιδ/μο'
          break;
      }
      const productOptions = JSON.stringify({
        quantity:quantity,
        rodPrice: rodPrice,
        windowWidth: windowWidth,
        obstacle: obstacle,
        obstacleDistance: obstacleDistance,
        rodWidth: rodWidth,
        metopi: metopiString,
        ringSelection: ringString,
        rodSelection: rodString,
        totalPrice: totalPrice,
        installation: installRodSelection,
        installPrice: installPrice,
        discount:discount,
        discountPrice: discount ? discountPrice:'',
        totalPriceDiscount: discount ? totalPriceDiscount:'',
        notes:notes
      })

      axios.post('https://api.orders.e-interior.gr/api/product/update',{
        key: cookies.Token,
        product_id: props.id,
        product_type: typeString,
        manufacturer: companySelection[0].label,
        code: sku,
        product_options: productOptions,
        },
        config
      )
      .then(function (response) {
        console.log(response.data);
        props.onSave({data:response.data})

      })
      .catch(function (error) {
        if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
        console.log(error);
      });
    }else{
      setErrorMessage('Missing required fields');
    }
    }
    useEffect(()=>{
      if (companies){
        setOptions(companies.map((key) => ({
                id: key,
                label: `${key}`,
              })))}
    }, [companies])

    useEffect(()=>{
      setTotalPriceDiscount(Number(discountPrice)*Number(quantity));
    },[discountPrice,quantity])
    useEffect (()=>{
      if (manualInstallPrice>0){
        setInstallPrice(manualInstallPrice*quantity);
      }
    },[quantity])
  return(
    <Modal className={classes.singleModal}>
      <h2 className={classes.title}>Παραγγελία Κουρτινόξυλου</h2>
      {errorMessage}
      <div>
        
      <div className={classes.textarea}>
      <label htmlFor="sku">Κωδικός/Περιγραφή: </label>
      <textarea id="sku" rows="2" value={sku} onChange={skuChangeHandler}></textarea>
      </div>
      <div className={classes.searchBlock}>
          <span>
            <label htmlFor="type">Εταιρεία: </label>
            {options && 
            <Typeahead
                className={classes.typeaheadSelect}
                id="basic-typeahead-single"
                labelKey="label"
                onChange={setCompanySelection}
                options={options}
                placeholder=""
                defaultSelected={companySelection}
                selected={companySelection}
            />}
          </span>
        </div>
      
      <div className={classes.searchBlock}>
        <span>
          <label htmlFor="type">Τύπος: </label>
          <select id="type" name="type" value={typeSelection} onChange={typeSelectionHandler}>
            <option value="0"> </option>
            <option value="hoops">Βέργα με κρίκους</option>
            <option value="rail">Σιδηρόδρομος (τιμή μέτρου)</option>
            <option value="fixedRail">Σιδηρόδρομος (fixed τιμές)</option>
            <option value="frame">Μετώπη/Κορνίζα (τιμή μέτρου)</option>
          </select>
        </span>
      </div>
      <Input
      onChange={windowWidthChangeHandler}
      onBlur={handleBlur}
      onFocus={handleFocus}
      label="Πλάτος Παραθύρου (m):"
      input={{
        id: 1,
        name: 'windowWidth',
        type: 'text',
        value: windowWidth
      }} />
      <div>
      <Input
      onChange={rodWidthChangeHandler}
      onBlur={handleBlur}
      onFocus={handleFocus}
      label="Πλάτος Κουρτινόξυλου (m):"
      input={{
        id: 1,
        name: 'rodWidth',
        type: 'text',
        value: rodWidth
      }} />
      {(windowWidth!='' &&typeSelection==='hoops' && obstacle == "No" ) &&
      <p className={classes.hint}>Το κουρτινόξυλο πρέπει να είναι τουλάχιστον {Math.round((parseFloat(windowWidth)+0.40)*100)/100}m</p>
      }
      {(windowWidth!='' &&typeSelection==='hoops' && obstacle == "Yes") &&
      <p className={classes.hint}>Το κουρτινόξυλο πρέπει να είναι τουλάχιστον {Math.round((parseFloat(windowWidth)+0.40-(parseFloat(obstacleDistance)/100))*100)/100}m</p>
      }
      {(windowWidth!='' && (typeSelection === "rail") ) &&
      <p className={classes.hint}>Προτεινόμενο πλάτος (m): {Math.round((parseFloat(windowWidth)+0.3-(parseFloat(obstacleDistance)*2/100))*100)/100}m</p>}
      {(windowWidth!='' && (typeSelection === "fixedRail"))&&
      <p className={classes.hint}>Προτεινόμενο πλάτος (m): {Math.round((parseFloat(windowWidth)+0.3-(parseFloat(obstacleDistance)*2/100))*100)/100}m</p>}
      {(windowWidth!='' && typeSelection === "frame") &&
      <p className={classes.hint}>Προτεινόμενο πλάτος για να είναι ισομοιρασμένο: {Math.round((parseFloat(windowWidth)+0.4-(parseFloat(obstacleDistance)*2/100))*100)/100}m</p>}
      </div>
      {typeSelection!=="0" &&
      <Fragment>
      {(typeSelection === "rail" || typeSelection==="frame") &&
      <Input
      onChange={rodPriceHandler}
      label="Τιμή μέτρου:"
      isPrice="true"
      input={{
        id: 1,
        type: 'text',
        value: rodPrice
      }} /> }


      <div className={classes.searchBlock}>
        <span>
          <label htmlFor="obstacle">Υπάρχει εμπόδιο αριστερά ή δεξιά από το παράθυρο σε απόσταση μικρότερη των 20cm? </label>
          <select id="obstacle" name="obstacle" value={obstacle} onChange={obstacleSelectionHandler}>
            <option value="No">Όχι</option>
            <option value="Yes">Ναι</option>
          </select>
        </span>
      </div>
      <div>
      <p></p>
      {obstacle ==="Yes" &&
      <Fragment>
      <Input
      onChange={obstacleDistanceChangeHandler}
      label="Απόσταση έως εμπόδιο (εκ):"
      input={{
        id: 1,
        type: 'text',
        value: obstacleDistance
      }} /> {obstacleDistance && <p className={classes.hint} style={{textAlign:'right',marginRight:'30px'}}>{obstacleDistance/100}m</p>}</Fragment>}
      </div>
      {(typeSelection === "frame") &&
        <Fragment>
      <div className={classes.searchBlock}>
        <span>
          <label htmlFor="choice">Επιλέξτε:</label>
          <select id="choice" name="choice" onChange={metopiSelectionHandler}>
            <option value="0"> </option>
            <option value="metopi">Μετώπη μόνο</option>
            <option value="metopiRail">Μετώπη με σιδ/μο</option>
          </select>
        </span>
      </div>
      </Fragment>}
      { (typeSelection === "rail" || typeSelection === "fixedRail")&&
      <Fragment>
      <div className={classes.searchBlock}>
        <span>
          <label htmlFor="rings">Δαχτυλίδια στήριξης</label>
          <select id="rings" name="rings" onChange={ringSelectionHandler}>
            <option value="No">Όχι</option>
            <option value="Yes">Ναι (+5,00€)</option>
          </select>
        </span>
      </div>
      </Fragment>}
      {typeSelection === "hoops" &&
      <div className={classes.searchBlock}>
        <span>
          <label htmlFor="rod">Κουρτινόξυλο</label>
          <select id="rod" name="rod" onChange={rodSelectionHandler}>
            <option value="mono">Μονό</option>
            <option value="doubleOval">Διπλό με Οβάλ</option>
            <option value="doubleRail">Διπλό με Βέργα</option>
          </select>
        </span>
      </div>}
      {typeSelection === "fixedRail" &&
        <div className={classes.searchBlock}>
        <span>
          <label htmlFor="rod">Κουρτινόξυλο</label>
          <select id="rod" name="rod" onChange={rodSelectionHandler}>
            <option value="mono">Μονό</option>
            <option value="double">Διπλό</option>
            <option value="triple">Τριπλό</option>
          </select>
        </span>
      </div>
      }
      { (typeSelection != "0" && typeSelection !="frame") &&
      <Fragment>
      <div>
      {((typeSelection === 'rail') && ringSelection === 'No') &&
        <Fragment>
        <p>{(rodWidth.length>0 && rodPrice.length>0)&& "Τιμή: "+totalPrice+'€'}</p>
        <p className={classes.hint}>{(rodWidth.length>0 && rodPrice.length>0)&&"Πλάτος κουρτινόξουλου Χ τιμή μέτρου"}</p>
        </Fragment>
      }
      {((typeSelection === 'rail' ) && ringSelection === 'Yes')&&
      <Fragment>
        <p>{(rodWidth.length>0 && rodPrice.length>0)&& "Τιμή: "+totalPrice+'€'}</p>
        <p className={classes.hint}>{(rodWidth.length>0 && rodPrice.length>0)&&"Πλάτος κουρτινόξουλου Χ τιμή μέτρου + 5"}</p>
      </Fragment>
      }
      {(typeSelection === 'hoops' || typeSelection === "fixedRail")&&

      <Fragment>
      <Input
      onChange={totalPriceChangeHandler}
      label="Τιμή:"
      isPrice="true"
      input={{
        id: 1,
        type: 'text',
        value: totalPrice
      }} />
      </Fragment>
        }
      </div>
      <div>
      <Button className={classes.discountBtn} onClick={discountChangeHandler}>Έκπτωση;</Button>
      {!discount && <p></p>}
      {discount && 
      <Fragment>
      <Input
      onChange={discountPriceChangeHandler}
      label="Τιμή με έκπτωση:"
      isPrice="true"
      input={{
        id: 1,
        type: 'text',
        value: discountPrice
      }} />
      <p>Σύνολο με έκπτωση: {totalPriceDiscount}€</p>
      </Fragment>
      }
      </div>
      
      { (typeSelection === "hoops" && obstacle === "Yes") &&
    <p> <strong>Θα πρέπει να υπολογιστούν +10€ στην τιμή για την ροζέτα</strong></p>}
      </Fragment>
      }
      {typeSelection === "frame" &&
      <Fragment>
        <div >
          <p>{(rodWidth.length>0 && rodPrice.length>0)&& "Τιμή: "+totalPrice+'€'}</p>
          <p className={classes.hint}>{(rodWidth.length>0 && rodPrice.length>0)&& "(Πλάτος κουρτινόξουλου +0.31) Χ τιμή μέτρου"}</p>
        </div>
        <div>
        <Button className={classes.discountBtn} onClick={discountChangeHandler}>Έκπτωση;</Button>
        {!discount && <p></p>}
        {discount && 
      <Fragment>
      <Input
      onChange={discountPriceChangeHandler}
      label="Τιμή με έκπτωση:"
      isPrice="true"
      input={{
        id: 1,
        type: 'text',
        value: discountPrice
      }} />
      <p>Σύνολο με έκπτωση: {totalPriceDiscount}€</p>
      </Fragment>
      }
        </div>
      </Fragment>
      }


      <div className={classes.searchBlock}>
        <span>
          <label htmlFor="install">Τοποθέτηση: </label>
          <select id="install" name="install" onChange={installRodSelectionHandler}>
            <option value="oxi">Όχι</option>
            <option value="nai">Ναι</option>
          </select>
        </span>
      </div>
      {installRodSelection == "nai" &&
      <Fragment>
      <Input onChange={installPriceChangeHandler}
      label="Τιμή τοποθέτησης:"
      isPrice="true"
      input={{
        id: 1,
        type: 'text',
        value: installPrice
      }} />
      </Fragment>

      }</Fragment>}

      <div className={classes.textarea}>
      <label htmlFor="sku">Σημειώσεις: </label>
      <textarea id="sku" rows="2" value={notes} onChange={notesChangeHandler}></textarea>
      </div>
      <Input
      onChange={quantityChangeHandler}
      label="Ποσότητα:"
      input={{
        id: 1,
        type: 'text',
        value: quantity
      }} />

      </div>
      <div className={classes.actions}>
        <button className={classes[`button--alt`]} onClick={props.onClose}>Πίσω</button>
        <button className={classes[`button--alt`]} onClick={handleSaveClick} >Αποθήκευση</button>
      </div>
    </Modal>
  )
}

export default CurtainRodEdit;
