import React,{useState,useEffect, Fragment} from 'react';
import Card from '../UI/Card';
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";
import {useCookies} from 'react-cookie';
import ClientsImport from './ClientsImport'
import ClientView from './ClientView'
import ClientEdit from './ClientEdit'
import ClientCreate from './ClientCreate'
import Button from '../UI/Button'
import classes from './Clients.module.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPencilAlt, faSave, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Typeahead } from 'react-bootstrap-typeahead'; 

const pencil = <FontAwesomeIcon icon={faPencilAlt} />
const save = <FontAwesomeIcon icon={faSave} />
const plus = <FontAwesomeIcon icon={faPlus} />

const Clients = () =>{
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const [clients,setClients] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [update, setUpdate] = useState('');
  const [modal, setModal] = useState('');
  const [singleSelections, setSingleSelections] = useState([]);
  const [options,setOptions]=useState([{id:'',label:''}])


  useEffect(() => {
    setIsLoaded(true);

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://api.orders.e-interior.gr/api/verify',
    headers: { 
      'Accept': 'application/json', 
      'Authorization': 'Bearer '+cookies.Token, 
    },
  };

  axios.request(config)
  .then((response) => {
    console.log(response.data)
    if (response.data.message!=="Success"){
      removeCookies('Name', { path: '/' });
      removeCookies('Token', { path: '/' });
      navigate("/user-login");
    };
  })
  .catch((error) => {
    removeCookies('Name', { path: '/' });
    removeCookies('Token', { path: '/' });
    navigate("/user-login");
  });

}, []);

    useEffect(() => {
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
    };

    //get all clients
    axios.post('https://api.orders.e-interior.gr/api/clients',{
      key: cookies.Token},
      config
    )
    .then(function (response) {
      setClients(response.data);
    })
    .catch(function (error) {
      console.log(error.response.data.message);
      if (error.response.data.message==="Unauthenticated."){
        removeCookies('Name', { path: '/' });
        removeCookies('Token', { path: '/' });
        navigate("/user-login");
      }
    });

  },[isLoaded, update])

    useEffect(() => {
      let opt = [];
      for (let client of clients){
        if (client.deleted===0){
          opt.push({
          id: client.id,
          label: client.first_name+" "+client.last_name+" - "+client.phone,
          })
        }
      }
      setOptions(opt);
    },[clients])

    const handleView = (e) =>{
      setModal(<ClientView id={e.id} onClose={viewCloseHandler}/>)
    }
    const viewCloseHandler = () =>{
      setModal('')
    }
    const handleEdit = (e) =>{
      setModal(<ClientEdit id={e.id} onClose={editCloseHandler} onSave={editSaveHandler}/>)
    }
    const editCloseHandler = () =>{
      setModal('');
    }
    const editSaveHandler = (e) =>{
      setModal('');
      setUpdate(e)
    }
    const handleDelete = (e) =>{
      setUpdate(e);
    }

      function handleClearClick(){
    setSingleSelections([]);
  }

    const handleNewClientClick = (e) =>{
      setModal(<ClientCreate id={e.id} onClose={newClientCloseHandler} onSave={newClientSaveHandler}/>)
    }
    const newClientCloseHandler = () =>{
      setModal('');
    }
    const newClientSaveHandler = (e) =>{
      setModal('');
      setUpdate(e)
    }

    const handleVip = (e) =>{
        const config = {
            headers: { 'Authorization': 'Bearer '+cookies.Token}
          };
        axios.post('https://api.orders.e-interior.gr/api/client/update',{
              client_id:e.id,
              vip:(e.vip!==1?true:false)
            }, config)
          .then(function (response) {
            setUpdate(response.data);
          })
          .catch(function (error) {
            if (error.response.data.message==="Unauthenticated."){
            removeCookies('Name', { path: '/' });
            removeCookies('Token', { path: '/' });
            navigate("/user-login");
          }
            console.log(error);
          });
    }

    const handleWarning = (e) =>{
      console.log(e.warning)
        const config = {
            headers: { 'Authorization': 'Bearer '+cookies.Token}
          };
        axios.post('https://api.orders.e-interior.gr/api/client/update',{
              client_id:e.id,
              warning:(e.warning!==1?true:false)
            }, config)
          .then(function (response) {
            setUpdate(response.data);
          })
          .catch(function (error) {
            if (error.response.data.message==="Unauthenticated."){
            removeCookies('Name', { path: '/' });
            removeCookies('Token', { path: '/' });
            navigate("/user-login");
          }
            console.log(error);
          });
    }
    


    return(
    <Card className={classes.ClientsCard}>
    <h2>Πελάτες</h2>
    {modal}
    <div className={classes.searchBlock}>
    <Typeahead
          className={classes.typeaheadSelect}
          id="basic-typeahead-single"
          labelKey="label"
          onChange={setSingleSelections}
          options={options}
          placeholder="Αναζήτηση Ονόματος ή Τηλεφώνου:"
          selected={singleSelections}
        />
    <Button onClick={handleClearClick}>Καθαρισμός</Button>
    </div>
    <div className={classes.newClient}>
    <Button onClick={handleNewClientClick} className={classes.newClientBtn}>Προσθήκη νέου Πελάτη {plus}</Button></div>
    <table>
      <thead>
        <tr>
          <th>#</th>
          <th>Όνομα</th>
          <th>Τηλέφωνο</th>
          <th>Παραγγελίες</th>
          <th>Σύνολο Παραγγελιών</th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
      {clients.map(client =>{
        if (client.deleted===0&&singleSelections.length===0){
        return(
          <ClientsImport
            onView={handleView}
            onEdit={handleEdit}
            onVip={handleVip}
            onWarning={handleWarning}
            onDelete={handleDelete}
            id={client.id}
            full_name={client.first_name+" "+client.last_name}
            phone={client.phone}
            vip={client.vip}
            warning={client.warning}
            totalOrders={client.totalOrders}
            totalIncome={client.totalIncome}
          />
          )
        }else if(client.deleted===0&&singleSelections[0].label===client.first_name+" "+client.last_name+" - "+client.phone){
          return(
            <ClientsImport
            onView={handleView}
            onEdit={handleEdit}
            onVip={handleVip}
            onWarning={handleWarning}
            onDelete={handleDelete}
            id={client.id}
            full_name={client.first_name+" "+client.last_name}
            phone={client.phone}
            vip={client.vip}
            warning={client.warning}
            totalOrders={client.totalOrders}
            totalIncome={client.totalIncome}
          />
          )
        }
      })}
      </tbody>
    </table>
    </Card>
    )
}


export default Clients;