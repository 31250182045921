import React,{useState, useEffect} from 'react';
import axios from 'axios';
import { Outlet, useParams, useMatch } from "react-router-dom";
import Card from '../UI/Card'
import {useCookies} from 'react-cookie';
import classes from './NewOrderEmployee.module.css'
import { useNavigate } from "react-router-dom";
const NewOrderEmployee = (props) => {
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const navigate = useNavigate();
//   useEffect(() => {

//   let config = {
//     method: 'post',
//     maxBodyLength: Infinity,
//     url: 'https://api.orders.e-interior.gr/api/verify',
//     headers: { 
//       'Accept': 'application/json', 
//       'Authorization': 'Bearer '+cookies.Token, 
//     },
//   };

//   axios.request(config)
//   .then((response) => {
//     console.log(response.data)
//     if (response.data.message!=="Success"){
//       removeCookies('Name', { path: '/' });
//       removeCookies('Token', { path: '/' });
//       navigate("/user-login");
//     };
//   })
//   .catch((error) => {
//     removeCookies('Name', { path: '/' });
//     removeCookies('Token', { path: '/' });
//     navigate("/user-login");
//   });

// }, []);
  const currentTab = useMatch("/new-order-employee/*").params["*"];
  return(
    <Card>
    <div className={classes.tabs}>
    <p  className={`${currentTab>="1" ? classes.selected:''} ${currentTab>1? classes.previous:''}`}>1. Προϊόντα</p>
    <p className={`${currentTab>="2" ? classes.selected:''} ${currentTab>2? classes.previous:''}`}>2. Εργασίες</p>
    </div>
    <Outlet />
    </Card>
  )
}

export default NewOrderEmployee;
