import React,{useState,useEffect,Fragment} from 'react';
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";
import {useCookies} from 'react-cookie';
import Modal from '../UI/Modal';
import Input from '../UI/Input';
import Button from '../UI/Button';
import OrderContext from '../../store/order-context'
import classes from '../NewOrder/NewOrderProducts/Persides.module.css';
import axios from 'axios';
import { Typeahead } from 'react-bootstrap-typeahead'; 

const PersidesEdit = (props) =>{

  //states
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const [companies, setCompanies]=useState('');
  const [companySelection, setCompanySelection] = useState([]);
  const [companyDropdown, setCompanyDropdown] = useState('');
  const [sku, setSku]=useState('');
  const [quantity, setQuantity]=useState('1');
  const [ftSquarePrice,setFtSquarePrice]=useState('');
  const [discount, setDiscount]=useState('');
  const [discountPrice, setDiscountPrice] = useState('');
  const [totalPriceDiscount, setTotalPriceDiscount]=useState('');
  const [typeSelection, setTypeSelection]=useState('0');
  // const [price, setPrice] = useState('');[]
  const [installation, setInstallation] = useState('oxi');
  const [installationPrice, setInstallationPrice] = useState('');
  const [manualInstallPrice, setManualInstallPrice] = useState('');
  //kathetes
  const [mechanismSelection, setMechanismSelection]=useState('0');
  const [quantityFyllou,setQuantityFyllou]=useState('');
  const [widthFyllou,setWidthFyllou]=useState('');

  //otan yparxei mhxanismos
  const [controllerPlacementSelection, setControllerPlacementSelection] = useState();
  const [directionSelection, setDirectionSelection] = useState('');
  const [supportSelection, setSupportSelection] = useState('');
 //from roller
  const [windowWidth, setWindowWidth]=useState('');
  const [windowHeight, setWindowHeight]=useState('');
  const [installPosition, setInstallPosition]=useState("outside");
  const [wayOfOpening, setWayOfOpening]=useState('0');
  const [obstacle, setObstacle]=useState('No');
  const [distance, setDistance]=useState('');
  const [aboveWindow, setAboveWindow] = useState('');
  const [suggestedWidth, setSuggestedWidth]=useState('');
  const [suggestedHeight, setSuggestedHeight]=useState('');
  const [rollerWidth, setRollerWidth]=useState('');
  const [rollerHeight, setRollerHeight]=useState('');
  const [windowHandle, setWindowHandle]=useState('0');
  const [totalPrice,setTotalPrice]=useState('');
  const [freeText,setFreeText]=useState('');

  const [errorMessage, setErrorMessage] =useState('');

  const [isLoaded, setIsLoaded] = useState(false);
  const [productInfo, setProductInfo] = useState('');
  const [productOptions, setProductOptions] = useState('');
  const [options,setOptions]=useState({id:'',label:''});

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    console.log('proped id is :' +props.id);
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
    };
    axios.post('https://api.orders.e-interior.gr/api/general/init',{
      key: cookies.Token},
      config
    )
    .then(function (response) {
      setCompanies(response.data[0].companies);

    })
    .catch(function (error) {
      console.log(error);
    });
    axios.post('https://api.orders.e-interior.gr/api/product/'+props.id,{
      key: cookies.Token},
      config
    )
    .then(function (response) {
      console.log(response.data);
      setProductInfo(response.data)
      setProductOptions(JSON.parse(response.data.product_options))
    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
          removeCookies('Name', { path: '/' });
          removeCookies('Token', { path: '/' });
          navigate("/user-login");
        }
      console.log(error);
    });
  },[])

  useEffect(() => {
      let typeString ='';
      switch(productInfo.product_type){
      case "Περσίδες με στόρια":
        typeString='Storia'
        break;
      case "Κάθετες Περσίδες":
        typeString='Kathetes'
        break;
      }
      let mechanismString=''
      switch(productOptions.mechanismSelection){
        case "0":
          mechanismString = "0";
          break;
        case "Με μηχανισμό":
          mechanismString = "Both";
          break;
        case "Μόνο φύλλα":
          mechanismString = "Fylla";
          break;
        case "Μόνο μηχανισμός":
          mechanismString = "Mechanism";
          break;
        default:
          mechanismString = "0";
          break;
      }

      let controllerString=''
      switch(productOptions.controllerPlacementSelection){
        case "0":
          controllerString = "0";
          break;
        case "Αριστερά":
          controllerString = "left";
          break;
        case "Δεξιά":
          controllerString = "right";
          break;
        default:
          controllerString = "";
          break;
      }

      let directionString=''
      switch(productOptions.directionSelection){
        case "0":
          directionString = "0";
          break;
        case "Αριστερά":
          directionString = "left";
          break;
        case "Δεξιά":
          directionString = "right";
          break;
        case "Αυλαία":
          directionString = "both";
          break;
        default:
          directionString = "";
          break;
      }
      let supportString=''
      switch(productOptions.supportSelection){
        case "0":
          supportString = "0";
          break;
        case "Οροφή":
          supportString = "ceiling";
          break;
        case "Τοίχος":
          supportString = "wall";
          break;
        default:
          supportString = "0";
          break;
      }
      let installPositionString=''
      switch(productOptions.installPosition){
        case "Έξω":
        installPositionString = "outside";
        break;
      case "Μέσα":
        installPositionString = "inside";
        break;
      default:
        installPositionString = "outside";
        break;
      }
      let wayOfOpeningString=''
      switch(productOptions.wayOfOpening){
        case "0":
          wayOfOpeningString = "0";
          break;
        case "Ανοιγόμενο":
          wayOfOpeningString = "open";
          break;
        case "Συρόμενο":
          wayOfOpeningString = "slide";
          break;
        default:
          wayOfOpeningString = "";
          break;
      }
    setCompanySelection((productInfo.manufacturer && productInfo.manufacturer !=0)  ? [productInfo.manufacturer]:[]);
    setSku(productInfo.code);
    setQuantity(productOptions.quantity ? productOptions.quantity:'1');
    setFtSquarePrice(productOptions.ftSquarePrice ? productOptions.ftSquarePrice:'');
    setDiscount(productOptions.discount ? productOptions.discount:'');
    setDiscountPrice(productOptions.discountPrice ? productOptions.discountPrice:'');
    setTotalPriceDiscount(productOptions.totalPriceDiscount ? productOptions.totalPriceDiscount:'');
    setTypeSelection(typeString);
    setInstallation(productOptions.installation ? productOptions.installation:'oxi');
    setInstallationPrice(productOptions.installPrice ? productOptions.installPrice:'');
    setManualInstallPrice(productOptions.installPrice ? productOptions.installPrice/productOptions.quantity:'')
    setMechanismSelection(mechanismString);
    setQuantityFyllou(productOptions.quantityFyllou ? productOptions.quantityFyllou:'');
    setWidthFyllou(productOptions.widthFyllou ? productOptions.widthFyllou:'');
    setControllerPlacementSelection(controllerString);
    setDirectionSelection(directionString);
    setSupportSelection(supportString);
    setWindowWidth(productOptions.windowWidth ? productOptions.windowWidth:'');
    setWindowHeight(productOptions.windowHeight ? productOptions.windowHeight:'');
    setInstallPosition(installPositionString);
    setWayOfOpening(wayOfOpeningString);
    setObstacle(productOptions.obstacle ? productOptions.obstacle:'No');
    setDistance(productOptions.distance ? productOptions.distance:'');
    setAboveWindow(productOptions.aboveWindow ? productOptions.aboveWindow:'');
    setRollerWidth(productOptions.rollerWidth ? productOptions.rollerWidth:'');
    setRollerHeight(productOptions.rollerHeight ? productOptions.rollerHeight:'');
    setTotalPrice(productOptions.totalPrice ? productOptions.totalPrice:'');
    setFreeText(productOptions.freeText ? productOptions.freeText:'');
  },[productOptions])

  const handleFocus = (event) => {
    const { name, value } = event.target;
    if (value){
    const inputValue = parseFloat(value);
    
    if (name === 'windowWidth') {
      setWindowWidth(inputValue);
    } else if (name === 'windowHeight') {
      setWindowHeight(inputValue);
    } else if (name === 'rollerWidth') {
      setRollerWidth(inputValue);
    } else if (name === 'rollerHeight') {
      setRollerHeight(inputValue);
    }
  }
  }
  const handleBlur = (event) => {
    const { name, value } = event.target;
    if (value){
    const inputValue = parseFloat(value);
    const formattedValue = inputValue.toFixed(3) + 'm';
    
    if (name === 'windowWidth') {
      setWindowWidth(formattedValue);
    } else if (name === 'windowHeight') {
      setWindowHeight(formattedValue);
    } else if (name === 'rollerWidth') {
      setRollerWidth(formattedValue);
    } else if (name === 'rollerHeight') {
      setRollerHeight(formattedValue);
    }
  }
  }
  const companySelectionHandler = (event) =>{
    setCompanySelection(event.target.value);
  }
  const skuChangeHandler = (event)=>{
    setSku(event.target.value);
  }
  const quantityChangeHandler = (event)=>{
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    value = value.trim();
    if (value === '') {
      setQuantity(''); // Set quantity as an empty string if input is empty
    } else {
      value = parseInt(value);
      if (isNaN(value)) {
        setQuantity(''); // Set quantity as an empty string if value is NaN
      } else {
        setQuantity(value);
      }
    }
  }
  const ftSquarePriceChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setFtSquarePrice(value);
    }
  }
  const discountChangeHandler = (event) =>{
    if (discount){
      setDiscount(false);
    }else{
      setDiscount(true);
    }
  }
  const discountPriceChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setDiscountPrice(value);
    }
  }
  const typeSelectionHandler = (event) =>{
    setTypeSelection(event.target.value);
    setAboveWindow('');
  }
  const mechanismSelectionHandler = (event) =>{
    setMechanismSelection(event.target.value);
  }
  // const priceChangeHandler = (event) =>{
  //   // replace , with .
  //   let value = event.target.value.toString();
  //   value = value.replace(/,/g,".");
  //   setPrice(value);
  // }


  const widthFyllouSelectionHandler = (event) =>{
    setWidthFyllou(event.target.value);
  }
  const quantityFyllouChangeHandler = (event) =>{
    setQuantityFyllou(event.target.value);
  }

  const controllerPlacementSelectionHandler = (event) =>{
    setControllerPlacementSelection(event.target.value);
  }
  const directionSelectionHandler = (event) =>{
    setDirectionSelection(event.target.value);
  }
  const supportSelectionHandler = (event) =>{
    setSupportSelection(event.target.value);
  }

  const installationSelectionHandler = (event) =>{
    setInstallation(event.target.value);
  }
  const installationPriceChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setInstallationPrice(value);
      setManualInstallPrice(value);
    }
  }

  //from roller
  const rollerWidthChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setRollerWidth(value);
    }
  }
    const rollerHeightChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setRollerHeight(value);
    }
  }
  const windowWidthChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setWindowWidth(value);
    }
  }
  const windowHeightChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setWindowHeight(value);
    }
  }
  const wayOfOpeningHandler = (event) =>{
    setWayOfOpening(event.target.value);
  }
  const installPositionHandler = (event) =>{
    if (event.target.value == "inside"){
      setWayOfOpening("slide");
      setObstacle("No");
    }
    setInstallPosition(event.target.value);
    setAboveWindow('');
  }
  const obstacleHandler = (event) =>{
    setObstacle(event.target.value);
  }
  const distanceChangeHandler = (event) =>{
    setDistance(event.target.value);
  }
useEffect(()=>{
    if (installPosition === "inside"){
      setSuggestedWidth(Math.round((parseFloat(windowWidth)-(3/100))*100)/100);
      setSuggestedHeight(Math.round((parseFloat(windowHeight)-(2/100))*100)/100);
    }else if (obstacle === "Yes"){
      setSuggestedWidth(Math.round((parseFloat(windowWidth)-Number(distance/100)*2)*100)/100);
      setSuggestedHeight(Math.round((parseFloat(windowHeight)+(5/100)+Number(aboveWindow/100))*100)/100);
    }else if (obstacle === "No"){
      setSuggestedWidth(Math.round((parseFloat(windowWidth)+(20/100))*100)/100);
      setSuggestedHeight(Math.round((parseFloat(windowHeight)+(5/100)+Number(aboveWindow/100))*100)/100);
    }
    
  },[installPosition,windowWidth,windowHeight,distance,aboveWindow])

  const aboveWindowChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setAboveWindow(value);
    }
  }


  const freeTextChangeHandler = (event) =>{
    setFreeText(event.target.value);
  }

  const totalPriceChangeHandler = (event) =>{
    // replace , with .
    let value = event.target.value.toString();
    value = value.replace(/,/g,".");
    
    const regex = /^\d*\.?\d{0,3}$/;

    if (regex.test(value) || value === '') {
      setTotalPrice(value);
    }
  }
  useEffect(()=>{
    if (typeSelection==='Storia'){
      setTotalPrice(Math.round(((Math.max(parseFloat(rollerHeight),1)*Math.max(parseFloat(rollerWidth),1))*ftSquarePrice)*quantity*100)/100);
    }else if (typeSelection==='Kathetes'){
      if (mechanismSelection==='Mechanism'){
        setTotalPrice(Math.round((Math.max(parseFloat(rollerWidth),1)*ftSquarePrice)*quantity*100)/100);
      }else if (mechanismSelection==='Fylla'){
        setTotalPrice(Math.round(((Math.max(parseFloat(rollerHeight),1.5)*(widthFyllou/1000))*ftSquarePrice)*quantity*100)/100);
      }else{
        setTotalPrice(Math.round(((Math.max(parseFloat(rollerHeight),1.5)*Math.max(parseFloat(rollerWidth),1))*ftSquarePrice)*quantity*100)/100);
      }
    }
  },[rollerHeight,rollerWidth,ftSquarePrice,typeSelection,mechanismSelection, quantity])


  const handleSaveClick = () =>{
    if (companySelection!='0'&&sku!=''&&ftSquarePrice!=''&&typeSelection!='0'){
      const config = {
        headers: { 'Authorization': 'Bearer '+cookies.Token}
      };

      let mechanismString=''
      switch(mechanismSelection){
        case "0":
          mechanismString = "0";
          break;
        case "Both":
          mechanismString = "Με μηχανισμό";
          break;
        case "Fylla":
          mechanismString = "Μόνο φύλλα";
          break;
        case "Mechanism":
          mechanismString = "Μόνο μηχανισμός";
          break;
        default:
          mechanismString = "0";
          break;
      }

      let controllerString=''
      switch(controllerPlacementSelection){
        case "0":
          controllerString = "0";
          break;
        case "left":
          controllerString = "Αριστερά";
          break;
        case "right":
          controllerString = "Δεξιά";
          break;
        default:
          controllerString = "";
          break;
      }

      let directionString=''
      switch(directionSelection){
        case "0":
          directionString = "0";
          break;
        case "left":
          directionString = "Αριστερά";
          break;
        case "right":
          directionString = "Δεξιά";
          break;
        case "both":
          directionString = "Αυλαία";
          break;
        default:
          directionString = "";
          break;
      }
      let supportString=''
      switch(supportSelection){
        case "0":
          supportString = "0";
          break;
        case "ceiling":
          supportString = "Οροφή";
          break;
        case "wall":
          supportString = "Τοίχος";
          break;
        default:
          supportString = "0";
          break;
      }
      let installPositionString=''
      switch(installPosition){
        case "outside":
        installPositionString = "Έξω";
        break;
      case "inside":
        installPositionString = "Μέσα";
        break;
      default:
        installPositionString = "";
        break;
      }
      let wayOfOpeningString=''
      switch(wayOfOpening){
        case "0":
          wayOfOpeningString = "0";
          break;
        case "open":
          wayOfOpeningString = "Ανοιγόμενο";
          break;
        case "slide":
          wayOfOpeningString = "Συρόμενο";
          break;
        default:
          wayOfOpeningString = "";
          break;
      }


      const productOptions = JSON.stringify({
          quantity: quantity,
          ftSquarePrice: ftSquarePrice,
          discount: discount,
          discountPrice: discount ? discountPrice:'',
          totalPriceDiscount: discount ? totalPriceDiscount:'',
          installation: installation,
          installPrice: installationPrice,
          mechanismSelection: mechanismString,
          widthFyllou: widthFyllou,
          quantityFyllou: quantityFyllou,
          controllerPlacementSelection: controllerString,
          directionSelection: directionString,
          supportSelection: supportString,
          windowWidth: windowWidth,
          windowHeight: windowHeight ,
          installPosition: installPositionString,
          wayOfOpening: wayOfOpeningString,
          obstacle: obstacle,
          distance: distance,
          aboveWindow: aboveWindow,
          rollerWidth: rollerWidth,
          rollerHeight: rollerHeight,
          totalPrice: totalPrice,
          freeText: freeText,
        })
      let typeString ='';
      switch(typeSelection){
      case 'Storia':
        typeString="Περσίδες με στόρια"
        break;
      case 'Kathetes':
        typeString="Κάθετες Περσίδες"
        break;
      }

      axios.post('https://api.orders.e-interior.gr/api/product/update',{
        key: cookies.Token,
        product_id: props.id,
        product_type: typeString,
        manufacturer: companySelection[0].label,
        code: sku,
        product_options: productOptions,
        },
        config
      )
      .then(function (response) {
        console.log(response.data);
        props.onSave({data:response.data})

      })
      .catch(function (error) {
        if (error.response.data.message==="Unauthenticated."){
            removeCookies('Name', { path: '/' });
            removeCookies('Token', { path: '/' });
            navigate("/user-login");
          }
        console.log(error);
      });
    }else{
      setErrorMessage('Λείπουν υποχρεωτικά πεδία');
    }
    }

    useEffect(()=>{
      if (companies){
        setOptions(companies.map((key) => ({
                id: key,
                label: `${key}`,
              })))}
    }, [companies])
    
    useEffect(()=>{
      setTotalPriceDiscount(Number(discountPrice)*Number(quantity));
    },[discountPrice,quantity])

  useEffect (()=>{
    if (manualInstallPrice>0){
      setInstallationPrice(manualInstallPrice*quantity);
    }
  },[quantity])

  return(
    <Modal className={classes.singleModal}>
    <h2> Παραγγελία Περσίδες </h2>
    {errorMessage}
    <div>

    
    <div className={classes.textarea}>
    <label htmlFor="sku">Κωδικός/Περιγραφή: </label>
    <textarea id="sku" rows="2" value={sku} onChange={skuChangeHandler}></textarea>
    </div>
    <div className={classes.searchBlock}>
      <span>
          <label htmlFor="type">Εταιρεία: </label>
            {options && 
            <Typeahead
                className={classes.typeaheadSelect}
                id="basic-typeahead-single"
                labelKey="label"
                onChange={setCompanySelection}
                options={options}
                placeholder=""
                defaultSelected={companySelection}
                selected={companySelection}
            />}
        </span>
    </div>
    
    <Input
    onChange={ftSquarePriceChangeHandler}
    label="Τιμή Τετραγωνικού:"
    isPrice="true"
    input={{
      id: 1,
      type: 'text',
      value: ftSquarePrice
    }} />
    <div className={classes.searchBlock}>
      <span>
        <label htmlFor="type">Τύπος Περσίδων: </label>
        <select id="type" name="type" value={typeSelection} onChange={typeSelectionHandler}>
          <option value="0"> </option>
          <option value="Storia">Στόρια</option>
          <option value="Kathetes">Κάθετες Περσίδες</option>
        </select>
      </span>
    </div>
    { typeSelection !== "0" &&
    <Fragment>
    {typeSelection === "Kathetes" &&
    <Fragment>
    <div className={classes.searchBlock}>
      <span>
        <label htmlFor="type">Μηχανισμός: </label>
        <select id="type" name="type" value={mechanismSelection} onChange={mechanismSelectionHandler}>
          <option value="0"> </option>
          <option value="Both">Με μηχανισμό</option>
          <option value="Fylla">Μόνο φύλλα</option>
          <option value="Mechanism">Μόνο μηχανισμός</option>
        </select>
      </span>
    </div>
    <div className={classes.searchBlock}>
    <span>
      <label htmlFor="type">Πλάτος Φύλλου: </label>
      <select id="type" name="type" value={widthFyllou} onChange={widthFyllouSelectionHandler}>
        <option value="0"> </option>
        <option value="89">89mm</option>
        <option value="127">127mm</option>
      </select>
    </span>
    </div>
    { (mechanismSelection !== "0"  && mechanismSelection !=="Fylla")&&
    <Fragment>
    <div>
    <Input
    onChange={rollerWidthChangeHandler}
    onBlur={handleBlur}
    onFocus={handleFocus}
    label="Πλάτος(m):"
    input={{
      id: 1,
      name:'rollerWidth',
      type: 'text',
      value: rollerWidth
    }} />
    <p className={classes.hint}>Εισάγετε το πλάτος της επιφάνειας που θέλετε να καλυφθεί.</p>
    </div>
    </Fragment>}
    { (mechanismSelection ==="Fylla")&&
    <Fragment>
    <Input
    onChange={quantityFyllouChangeHandler}
    label="Φύλλα - τεμάχια:"
    input={{
      id: 1,
      type: 'text',
      value: quantityFyllou
    }} />
    </Fragment>}
{ (mechanismSelection === "Both" || mechanismSelection === "Fylla") &&
  <Fragment>
  <div>
    <Input
    onChange={rollerHeightChangeHandler}
    onBlur={handleBlur}
    onFocus={handleFocus}
    label="Ολικό Ύψος(m):"
    input={{
      id: 1,
      name: 'rollerHeight',
      type: 'text',
      value: rollerHeight
    }} />
    <p className={classes.hint}>Εισάγετε το ύψος της επιφάνειας που θέλετε να καλυφθεί αφαιρώντας 3-4 cm για να μην σέρνονται οι περσίδες στο δάπεδο.</p>
    </div>
  </Fragment>
  }
  { (mechanismSelection === "Both" || mechanismSelection === "Mechanism") &&
    <Fragment>
    <div className={classes.searchBlock}>
      <span>
        <label htmlFor="controllerPlacement">Θέση χειριστηρίων: </label>
        <select id="controllerPlacementSelection" name="controllerPlacementSelection" value={controllerPlacementSelection} onChange={controllerPlacementSelectionHandler}>
          <option value="0"> </option>
          <option value="left">Αριστερά</option>
          <option value="right">Δεξιά</option>
        </select>
      </span>
    </div>
    <div className={classes.searchBlock}>
      <span>
        <label htmlFor="direction">Πως θα μαζεύουν τα φύλλα; </label>
        <select id="direction" name="direction" value={directionSelection} onChange={directionSelectionHandler}>
          <option value="0"> </option>
          <option value="left">Αριστερά</option>
          <option value="right">Δεξιά</option>
          <option value="both">Αυλαία</option>
        </select>
      </span>
    </div>
    <div className={classes.searchBlock}>
      <span>
        <label htmlFor="type">Στήριξη σε: </label>
        <select id="support" name="support" value={supportSelection} onChange={supportSelectionHandler}>
          <option value="0"> </option>
          <option value="ceiling">Οροφή</option>
          <option value="wall">Τοίχος</option>
        </select>
      </span>
    </div>
    </Fragment>
  }
<div>
{totalPrice>0&&<p>Τιμή: {totalPrice}€ </p>}
</div>
  <div>
<Button className={classes.discountBtn} onClick={discountChangeHandler}>Έκπτωση;</Button>
{!discount && <p></p>}
{discount && 
      <Fragment>
      <Input
      onChange={discountPriceChangeHandler}
      label="Τιμή με έκπτωση:"
      isPrice="true"
      input={{
        id: 1,
        type: 'text',
        value: discountPrice
      }} />
      <p>Σύνολο με έκπτωση: {totalPriceDiscount}€</p>
      </Fragment>
      }
</div>
  <div className={classes.searchBlock}>
    <span>
      <label htmlFor="type">Τοποθέτηση: </label>
      <select id="installation" name="installation" value={installation} onChange={installationSelectionHandler}>
        <option value="oxi">Όχι</option>
        <option value="nai">Ναι</option>
      </select>
    </span>
  </div>
  { installation =="nai" &&
  <Input
  onChange={installationPriceChangeHandler}
  label="Κόστος:"
  input={{
    id: 1,
    type: 'text'
  }} />}
  </Fragment>}

</Fragment>}
{typeSelection === "Storia" &&
<Fragment>
<Input
onChange={windowWidthChangeHandler}
onBlur={handleBlur}
onFocus={handleFocus}
label="Πλάτος Παραθύρου (m):"
input={{
  id: 1,
  type: 'text',
  name: 'windowWidth',
  value: windowWidth
}} />
<Input
onChange={windowHeightChangeHandler}
onBlur={handleBlur}
onFocus={handleFocus}
label="Ύψος Παραθύρου (m):"
input={{
  id: 1,
  name: 'windowHeight',
  type: 'text',
  value: windowHeight
}} />

<div className={classes.searchBlock}>
  <span>
    <label htmlFor="type">Μέρος τοποθέτησης: </label>
    <select id="type" name="type" value={installPosition} onChange={installPositionHandler}>
      <option value="outside">Έξω</option>
      <option value="inside">Μέσα</option>
      
    </select>
  </span>
</div>
{installPosition === "outside" &&
<Fragment>
<div className={classes.searchBlock}>
  <span>
    <label htmlFor="type">Ανοιγόμενο ή Συρόμενο: </label>
    <select id="type" name="type" value={wayOfOpening} onChange={wayOfOpeningHandler}>
      <option value="0"> </option>
      <option value="open">Ανοιγόμενο</option>
      <option value="slide">Συρόμενο</option>
    </select>
  </span>
</div>
</Fragment>}
{ wayOfOpening !=="0" &&
<Fragment>
{installPosition === "outside" &&
<div className={classes.searchBlock}>
  <span>
    <label htmlFor="type">Υπάρχει εμπόδιο αριστερά ή δεξιά από το παράθυρο σε απόσταση μικρότερη των 20cm; </label>
    <select id="type" name="type" value={obstacle} onChange={obstacleHandler}>
      <option value="No">Όχι</option>
      <option value="Yes">Ναι</option>
      
    </select>
  </span>
  {obstacle === "Yes" &&
<Fragment>
<Input
onChange={distanceChangeHandler}
label="Απόσταση έως εμπόδιο (εκ):"
input={{
  id: 1,
  type: 'text',
  value: distance
}} />{distance && <p className={classes.hint} style={{textAlign:'right',marginRight:'30px'}}>{distance/100}m</p>}</Fragment>}
</div>}
{ (obstacle !=="0"|| installPosition === "inside") &&
<Fragment>
<div>
<Input
onChange={rollerWidthChangeHandler}
onBlur={handleBlur}
onFocus={handleFocus}
label="Πλάτος Περσίδων (m):"
input={{
  id: 1,
  name: 'rollerWidth',
  type: 'text',
  value: rollerWidth
}} />
<p className={classes.hint}>{windowWidth.length>0 && "Προτεινόμενο πλάτος: "+suggestedWidth+"m"}</p>
</div>
<div>
<Input
onChange={rollerHeightChangeHandler}
onBlur={handleBlur}
onFocus={handleFocus}
label="Τελικό ύψος Περσίδων (m):"
input={{
  id: 1,
  name: 'rollerHeight',
  type: 'text',
  value: rollerHeight
}} />
<p className={classes.hint}>{windowHeight.length>0 && "Προτεινόμενο ύψος Περσιδών: "+suggestedHeight+ "m" }</p>
</div>

{ (typeSelection === 'Storia' && installPosition !== 'inside' )&&
<Fragment>
<Input
onChange={aboveWindowChangeHandler}
label="Πόσο πάνω από το παράθυρο θα τοποθετηθούν οι περσίδες; (cm)"
input={{
  id: 1,
  type: 'text',
  value:aboveWindow
}} />
<p></p>
</Fragment>}


<div className={classes.searchBlock}>
  <span>
    <label htmlFor="type">Στήριξη σε: </label>
    <select id="type" name="type" value={supportSelection} onChange={supportSelectionHandler}>
      <option value="0"></option>
      <option value="ceiling">Οροφή</option>
      {installPosition === "outside" && <option value="Wall">Τοίχος</option>}
    </select>
  </span>
</div>
<div className={classes.searchBlock}>
  <span>
    <label htmlFor="controllerPlacement">Θέση χειριστηρίων: </label>
    <select id="controllerSelectionPlacement" name="controllerSelectionPlacement" value={controllerPlacementSelection} onChange={controllerPlacementSelectionHandler}>
      <option value="0"> </option>
      <option value="left">Αριστερά</option>
      <option value="right">Δεξιά</option>
    </select>
  </span>
</div>
<div className={classes.textarea}>
<label htmlFor="sku">Σημειώσεις: </label>
<textarea id="sku" rows="2" value={freeText} onChange={freeTextChangeHandler}></textarea>
</div>
<div>
<div>
{totalPrice>0&&<p>Τιμή: {totalPrice}€ </p>}
</div>

</div>
<div>
<Button className={classes.discountBtn} onClick={discountChangeHandler}>Έκπτωση;</Button>
{!discount && <p></p>}
{discount && 
      <Fragment>
      <Input
      onChange={discountPriceChangeHandler}
      label="Τιμή με έκπτωση:"
      isPrice="true"
      input={{
        id: 1,
        type: 'text',
        value: discountPrice
      }} />
      <p>Σύνολο με έκπτωση: {totalPriceDiscount}€</p>
      </Fragment>
      }
</div>
<div className={classes.searchBlock}>
  <span>
    <label htmlFor="type">Τοποθέτηση: </label>
      <select id="support" name="support" value={installation} onChange={installationSelectionHandler}>
        <option value="0">Όχι</option>
        <option value="nai">Ναι</option>
      </select>
  </span>
</div>
{ installation =="nai" &&
<Input
onChange={installationPriceChangeHandler}
label="Κόστος:"
input={{
  id: 1,
  type: 'text'
}} />}
</Fragment>}
</Fragment>}
</Fragment>}
<Input
      onChange={quantityChangeHandler}
      label="Ποσότητα:"
      input={{
        id: 1,
        type: 'text',
        value: quantity
      }} />
</div>
    <div className={classes.actions}>
      <button className={classes[`button--alt`]} onClick={props.onClose}>Πίσω</button>
      <button className={classes[`button--alt`]} onClick={handleSaveClick}>Αποθήκευση</button>
    </div>
    </Modal>
  )

}


export default PersidesEdit;
