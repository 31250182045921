import React,{Fragment, useState, useEffect} from 'react';
import {useCookies} from 'react-cookie';
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';



const NewOrderEmptyEmployee = () =>{
  const navigate = useNavigate();
  const location = useLocation();
  const [cookies] = useCookies(['user']);
  const [clientCookie, setClientCookie,removeClientCookie] = useCookies (['client']);
  const [isLoaded, setIsLoaded] = useState(false);


useEffect(()=>{
  
  const params = new URLSearchParams(location.search);
  const key = params.get('key');
  const presetKey = "4Id3OS8N1sRSsjpEw61PwckChIb14XT7";

  if (key !== presetKey) {
    console.log("Invalid key provided, not creating order.");
    return;
  }

  const config = {
      headers: { Authorization: "Bearer 5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp"}
    };

    

  axios.post('https://api.orders.e-interior.gr/api/order/create',{

        key: "5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp",
        invoice: 0,
        client_id: 0,
        extras:0,
        payment:0,
        status:"not_submitted_yet"
        },
        config
      )
      .then(function (response) {
        console.log(response.data);
        setClientCookie('OrderId',response.data.order_id,{path:'/',sameSite: 'None' , secure:true});
        navigate('../1');

      })
      .catch(function (error) {
        console.log(error);

      });
},[])

  return null;
}
export default NewOrderEmptyEmployee;
