import React, {useState, useEffect} from 'react';
import { useLocation, Routes, Route, Outlet } from 'react-router-dom';
import Header from './components/Layout/Header';
import MainButtons from './components/MainButtons/MainButtons';
import UserLogin from './components/UserLogin/UserLogin';
import NewOrder from './components/NewOrder/NewOrder';
import OrdersInProgress from './components/OrdersInProgress/OrdersInProgress';
import OrderHistory from './components/OrderHistory/OrderHistory';
import Placements from './components/Placements/Placements';
import ToOrder from './components/ToOrder/ToOrder';
import './App.css';

function App() {
  const location = useLocation();
  const noHeaderRoutes = ['/new-order-employee']
  const shouldHideHeader = noHeaderRoutes.some((route) =>
    location.pathname.startsWith(route)
  );

  return (
    <div className="App">
      {!shouldHideHeader ?
      <header className="App-header">
        <Header/>
      </header>
      :
      <Outlet/>}
    </div>
  );
}

export default App;
